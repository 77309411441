@import url(https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css);
@import url(https://fonts.googleapis.com/css?family=Space+Mono:400,700);
* {
  box-sizing: border-box;
}
html {
  overflow-y: scroll;
}

.container-center-horizontal{
  display: flex;
  align-items: center;
  justify-content: center;
}
.spacemono-normal-black-12px {
  color: rgba(0, 0, 0, 1);
  color: var(--black);
  font-family: "Space Mono", Helvetica;
  font-family: var(--font-family-space_mono);
  font-size: 12px;
  font-size: var(--font-size-12px);
  font-style: normal;
  font-weight: 400;
}

.spacemono-normal-black-15px {
  color: rgba(0, 0, 0, 1);
  color: var(--black);
  font-family: "Space Mono", Helvetica;
  font-family: var(--font-family-space_mono);
  font-size: var(--font-size-15px);
  font-style: normal;
  font-weight: 400;
}

:root {
  --black: rgba(0, 0, 0, 1);
  --font-family-space_mono: "Space Mono", Helvetica;
  --font-size-12px: 12px;
  --white: rgba(255, 255, 255, 1);
}

@media only screen and (max-width: 600px) {

}

.asset-20-gallery-2 {
    align-self: center;
    height: 15px;
    margin-bottom: 1.0px;
    margin-left: 31px;
    object-fit: cover;
    width: 85px;
  }
  .asset-21-purchase-2 {
    height: 17px;
    margin-left: 49px;
    object-fit: cover;
    width: 83px;
  }
  .asset-23-wallet-3 {
    height: 15px;
    margin-left: 29px;
    margin-top: 1px;
    object-fit: cover;
    width: 99px;
  }
  .asset-26-j48-messy-1 {
    height: 120px;
    left: 201px;
    object-fit: cover;
    position: absolute;
    top: 31px;
    width: 190px;
  }
  .asset-28-bounty-2 {
    height: 20px;
    margin-left: 30px;
    object-fit: cover;
    width: 88px;
  }
  .asset-3-l1-2 {
    height: 55px;
    left: 41px;
    object-fit: cover;
    position: absolute;
    top: 0px;
    width: 488px;
  }
  .asset-31-main-2 {
    height: 15px;
    object-fit: cover;
    width: 51px;
  }
  .asset-34-twitter-1 {
    height: 28px;
    margin-left: 14px;
    margin-top: 35.52px;
    object-fit: cover;
    width: 33px;
  }
  .asset-35-discord-1 {
    height: 22px;
    left: 503px;
    object-fit: cover;
    position: absolute;
    top: 83px;
    width: 40px;
  }
  .asset-41-grottolab1-1 {
    height: 48px;
    left: 5px;
    object-fit: cover;
    position: absolute;
    top: 60px;
    width: 58px;
  }
  
  .flex-row-navigation {
    display: flex;
    min-width: 590px;
    margin:0px auto;
    margin-top:20px;
    align-items: center;
    justify-content: center;
    z-index:2;
    position: relative;
  }

  .flex-row-footer {
    align-items: center;
    display: flex;
    margin:0px auto;
    height:150px;
    position: fixed;
    bottom:0px;
    background:white;
    border:1px solid red;
    width:100%;
  }

  .grotto-labs-logo-3 {
    height: 78px;
    left: 0px;
    object-fit: cover;
    position: absolute;
    top: 52px;
    width: 67px;
  }
  .overlap-group {
    height: 150px;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 528px;
  }
  .overlap-group1 {
    height: 150px;
    position: relative;
    width: 543px;
  }
  .asset-18-prov-2 {
    height: 15px;
    margin-left: 42px;
    object-fit: cover;
    width: 68px;
    cursor: pointer;
  }
  .asset-18-prov-grid {
    height: 18px;
    margin-left: 42px;
    object-fit: contain;
    width: 68px;
    cursor: pointer;
  }
  .asset-20-gallery-2 {
    align-self: center;
    height: 15px;
    margin-bottom: 1.0px;
    margin-left: 31px;
    object-fit: cover;
    width: 85px;
    cursor: pointer;
  }
  .asset-21-purchase-2 {
    height: 17px;
    margin-left: 49px;
    object-fit: cover;
    width: 83px;
    cursor: pointer;
  }
  .asset-23-wallet-3 {
    height: 15px;
    margin-left: 29px;
    margin-top: 1px;
    object-fit: cover;
    width: 99px;
    cursor: pointer;
  }
  .asset-26-j48-messy-1 {
    height: 120px;
    left: 201px;
    object-fit: cover;
    position: absolute;
    top: 31px;
    width: 190px;
  }
  .asset-28-bounty-2 {
    height: 20px;
    margin-left: 30px;
    object-fit: cover;
    width: 88px;
    cursor: pointer;
  }
  .asset-3-l1-2 {
    height: 55px;
    left: 41px;
    object-fit: cover;
    position: absolute;
    top: 0px;
    width: 488px;
  }
  .asset-31-main-2 {
    height: 15px;
    object-fit: cover;
    width: 51px;
  }
  .asset-34-twitter-1 {
    height: 28px;
    margin-left: 14px;
    margin-top: 35.52px;
    object-fit: cover;
    width: 33px;
  }
  .asset-35-discord-1 {
    height: 22px;
    left: 503px;
    object-fit: cover;
    position: absolute;
    top: 83px;
    width: 40px;
  }
  .asset-41-grottolab1-1 {
    height: 48px;
    left: 5px;
    object-fit: cover;
    position: absolute;
    top: 60px;
    width: 58px;
  }



.home {
  align-items: flex-end;
  background-color: var(--white);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 1898px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 789px;
}

.home .header-animation-style {
  background-color: transparent;
  height: 450px;
  left: 5px;
  position: absolute;
  top: 31px;
  width: 600px;
}

.home .asset-18-prov-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 15px;
  margin-bottom: 1.0px;
  margin-left: 42px;
  object-fit: cover;
  position: relative;
  width: 68px;
}
.home .asset-20-gallery-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 15px;
  margin-bottom: 1.0px;
  margin-left: 31px;
  object-fit: cover;
  position: relative;
  width: 85px;
}
.home .asset-21-purchase-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 17px;
  margin-bottom: 1.0px;
  margin-left: 49px;
  object-fit: cover;
  position: relative;
  width: 83px;
}
.home .asset-23-wallet-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 15px;
  margin-bottom: 1.0px;
  margin-left: 29px;
  object-fit: cover;
  position: relative;
  width: 99px;
}
.home .asset-26-j48-messy-1-xYI43P {
  background-color: transparent;
  height: 120px;
  left: 205px;
  object-fit: cover;
  position: absolute;
  top: 31px;
  width: 190px;
}
.home .asset-28-bounty-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 20px;
  margin-left: 30px;
  object-fit: cover;
  position: relative;
  width: 88px;
}
.home .asset-3-l1-1-y1HRxC {
  background-color: transparent;
  height: 55px;
  left: 83px;
  object-fit: cover;
  position: absolute;
  top: 420px;
  width: 488px;
}
.home .asset-3-l1-2-xYI43P {
  background-color: transparent;
  height: 55px;
  left: 45px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 488px;
}
.home .asset-31-main-1-dXBp89 {
  background-color: transparent;
  flex-shrink: 1;
  height: 15px;
  margin-bottom: 1.0px;
  object-fit: cover;
  position: relative;
  width: 51px;
}
.home .asset-34-twitter-1-4xxXEe {
  background-color: transparent;
  flex-shrink: 1;
  height: 28px;
  margin-left: 14px;
  margin-top: 79.0px;
  object-fit: cover;
  position: relative;
  width: 33px;
}
.home .asset-35-discord-1-xYI43P {
  background-color: transparent;
  height: 22px;
  left: 507px;
  object-fit: cover;
  position: absolute;
  top: 83px;
  width: 40px;
}
.home .asset-4-l3-1-xpmbvu {
  background-color: transparent;
  flex-shrink: 1;
  height: 47px;
  margin-left: 20.05px;
  object-fit: cover;
  position: relative;
  width: 585px;
}
.home .asset-41-grottolab1-1-xYI43P {
  background-color: transparent;
  height: 48px;
  left: 9px;
  object-fit: cover;
  position: absolute;
  top: 60px;
  width: 58px;
}
.home .asset-67curve-3-xpmbvu {
  background-color: transparent;
  flex-shrink: 1;
  height: 310px;
  margin-top: 24px;
  object-fit: cover;
  position: relative;
  width: 640px;
}
.home .barre-lj48-1-fKmFVR {
  background-color: transparent;
  flex-shrink: 1;
  height: 121px;
  object-fit: cover;
  position: relative;
  width: 110px;
}
.home .barre-lj48-2-fKmFVR {
  background-color: transparent;
  flex-shrink: 1;
  height: 121px;
  margin-left: 28px;
  object-fit: cover;
  position: relative;
  width: 110px;
}
.home .computer-genera-d-ganj48-baform-DTrHo1 {
  background-color: transparent;
  height: auto;
  left: 0px;
  letter-spacing: 0.00px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 174px;
  width: 261px;
}
.home .flex-col-0xy0vn {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 14px;
  min-height: 885px;
  position: relative;
  width: 640px;
}
.home .flex-col-4xxXEe {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 344px;
  position: relative;
  width: 547px;
}
.home .flex-col-C61RwL {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-right: -413.0px;
  margin-top: 100px;
  min-height: 2206px;
  position: relative;
  width: 1145px;
}
.home .flex-col-GyJUNH {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 524px;
  position: relative;
  width: 283px;
}
.home .flex-col-gkUG7I {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 33px;
  min-height: 523px;
  position: relative;
  width: 267px;
}
.home .flex-row-0xy0vn {
  align-items: flex-start;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 524px;
  justify-content: flex-start;
  margin-top: 6px;
  min-width: 1106px;
  position: relative;
  width: auto;
}
.home .flex-row-9Sxrs4 {
  align-items: flex-start;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 344px;
  justify-content: flex-start;
  margin-right: 10px;
  margin-top: 29px;
  min-width: 594px;
  position: relative;
  width: auto;
}
.home .flex-row-C61RwL {
  align-items: center;
  align-self: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 20px;
  justify-content: flex-start;
  margin-left: 3.46px;
  min-width: 657px;
  position: relative;
  width: auto;
}
.home .flex-row-xpmbvu {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 127px;
  justify-content: flex-start;
  margin-left: 20.0px;
  margin-top: 4px;
  min-width: 526px;
  position: relative;
  width: auto;
}
.home .frame-1-Ee5ApG {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  margin-right: 25.44px;
  margin-top: 192px;
  overflow: hidden;
  position: relative;
  width: 6px;
}
.home .grotto-labs-logo-6-xYI43P {
  background-color: transparent;
  height: 78px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 55px;
  width: 67px;
}
.home .hand-made-legacy-j48-baform-nFOi0F {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0.00px;
  line-height: normal;
  margin-right: 23.0px;
  margin-top: 8px;
  min-height: 18px;
  min-width: 208px;
  position: relative;
  text-align: left;
  width: auto;
}
.home .j48-baforms-pro-d-cared-for-art-3xHcwy {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0.00px;
  line-height: normal;
  min-height: 298px;
  position: relative;
  text-align: left;
  width: 283px;
}
.home .overlap-group-0xy0vn {
  background-color: transparent;
  flex-shrink: 1;
  height: 475px;
  margin-left: 8px;
  position: relative;
  width: 660px;
}
.home .overlap-group1-Ee5ApG {
  background-color: transparent;
  flex-shrink: 1;
  height: 150px;
  position: relative;
  width: 547px;
}
.home .overlap-group2-nFOi0F {
  background-color: transparent;
  flex-shrink: 1;
  height: 497px;
  position: relative;
  width: 267px;
}
.home .overlap-group3-3xHcwy {
  background-color: transparent;
  flex-shrink: 1;
  height: 193px;
  margin-left: 4.0px;
  margin-top: 33px;
  position: relative;
  width: 261px;
}
.home .purchasejabbaform-final-1-fKmFVR {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: 121px;
  margin-left: 28px;
  object-fit: cover;
  position: relative;
  width: 250px;
}
.home .purchasejabbaform-final-2-GyJUNH {
  align-self: center;
  background-color: transparent;
  flex-shrink: 1;
  height: 117px;
  margin-left: 293px;
  margin-top: 71.0px;
  object-fit: cover;
  position: relative;
  width: 230px;
}
.home .rectangle-1-y1HRxC {
  background-color: transparent;
  height: 550px;
  left: 0px;
  overflow: hidden;
  position: absolute;
  top: -50px;
  width: 660px;
}
.home .rectangle-1-y1HRxC img {
  object-fit: cover;
}
.home .rectangle-1-y1HRxC video {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.home .rectangle-2-jexT3O {
  background-color: transparent;
  height: 140px;
  left: 50px;
  object-fit: cover;
  position: absolute;
  top: 340px;
  width: 130px;
}

.home .rectangle-3-DTrHo1 {
  background-color: transparent;
  height: 250px;
  left: 9px;
  object-fit: cover;
  position: absolute;
  top: -60px;
  width: 200px;
}
.home .we-started-with-afted-with-love-jexT3O {
  background-color: transparent;
  height: auto;
  left: 0px;
  letter-spacing: 0.00px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 0px;
  width: 267px;
}

/* screen - home */
.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  -webkit-transition: opacity .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 23px;
  height: 24px;
  border: 0;
  background: black;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 23px;
  height: 24px;
  border: 0;
  background: green;
  cursor: pointer;
}

.num-purchase-jabbas {
  text-align: center
}

/* screen - purchase */

.purchase {
  align-items: flex-end;
  background-color: var(--white);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 2100px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  padding: 33px 35.3px;
  position: relative;
  width: 789px;
}
.purchase .asset-18-prov-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 15px;
  margin-bottom: 1.0px;
  margin-left: 42px;
  object-fit: cover;
  position: relative;
  width: 68px;
}
.purchase .asset-20-gallery-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 15px;
  margin-bottom: 1.0px;
  margin-left: 31px;
  object-fit: cover;
  position: relative;
  width: 85px;
}
.purchase .asset-21-purchase-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 17px;
  margin-bottom: 1.0px;
  margin-left: 49px;
  object-fit: cover;
  position: relative;
  width: 83px;
}
.purchase .asset-22-purchjabb-2-NG5cxJ {
  background-color: transparent;
  height: 88px;
  left: 230px;
  object-fit: cover;
  position: absolute;
  top: 741px;
  width: 255px;
  cursor: pointer;
  z-index:2;
}
.purchase .asset-23-wallet-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 15px;
  margin-bottom: 1.0px;
  margin-left: 29px;
  object-fit: cover;
  position: relative;
  width: 99px;
}
.purchase .asset-26-j48-messy-1-H5vPvf {
  background-color: transparent;
  height: 120px;
  left: 196px;
  object-fit: cover;
  position: absolute;
  top: 31px;
  width: 190px;
}
.purchase .asset-28-bounty-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 20px;
  margin-left: 30px;
  object-fit: cover;
  position: relative;
  width: 88px;
}
.purchase .asset-3-l1-2-H5vPvf {
  background-color: transparent;
  height: 55px;
  left: 36px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 488px;
}
.purchase .asset-31-main-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 15px;
  margin-bottom: 1.0px;
  object-fit: cover;
  position: relative;
  width: 51px;
}
.purchase .asset-34-twitter-1-fKmFVR {
  background-color: transparent;
  flex-shrink: 1;
  height: 28px;
  margin-left: 14px;
  margin-top: 35.52px;
  object-fit: cover;
  position: relative;
  width: 33px;
}
.purchase .asset-35-discord-1-ASQbkE {
  background-color: transparent;
  flex-shrink: 1;
  height: 22px;
  object-fit: cover;
  position: relative;
  width: 40px;
}
.purchase .asset-4-l3-2-52Wjwg {
  background-color: transparent;
  height: 47px;
  left: 3px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 613px;
}
.purchase .asset-41-grottolab1-1-H5vPvf {
  background-color: transparent;
  height: 48px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 60px;
  width: 58px;
}
.purchase .asset-67curve-2-xpmbvu {
  background-color: transparent;
  flex-shrink: 1;
  height: 310px;
  object-fit: cover;
  position: relative;
  width: 640px;
}
.purchase .bwgan-221-GyJUNH {
  background-color: transparent;
  flex-shrink: 1;
  height: 182px;
  margin-left: 118px;
  object-fit: cover;
  position: relative;
  width: 134px;
}
.purchase .bwgan-226-GyJUNH {
  align-self: center;
  background-color: transparent;
  flex-shrink: 1;
  height: 168px;
  margin-bottom: 8.0px;
  margin-left: 113px;
  object-fit: cover;
  position: relative;
  width: 149px;
}
.purchase .bwgan-251-GyJUNH {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: 181px;
  object-fit: cover;
  position: relative;
  width: 135px;
}
.purchase .clickthecan-1-0xy0vn {
  background-color: transparent;
  flex-shrink: 1;
  height: 36px;
  margin-left: 19.26px;
  margin-top: 25px;
  object-fit: cover;
  position: relative;
  width: 377px;
}
.purchase .currentpricejabbaform-1-52Wjwg {
  background-color: transparent;
  height: 151px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 34px;
  width: 309px;
}
.purchase .flex-col-0xy0vn {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-right: 29.74px;
  margin-top: 35px;
  min-height: 490px;
  position: relative;
  width: 640px;
}
.purchase .flex-col-C61RwL {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 28px;
  min-height: 1849px;
  position: relative;
  width: 710px;
}
.purchase .flex-col-H5vPvf {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 498px;
  min-height: 56px;
  position: absolute;
  top: 83px;
  width: 40px;
}
.purchase .flex-col-IMpwyX {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 637px;
  min-height: 194px;
  position: absolute;
  top: 672px;
  width: 72px;
}
.purchase .flex-col-NG5cxJ {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 0px;
  min-height: 179px;
  position: absolute;
  top: 676px;
  width: 73px;
}
.purchase .flex-row-0xy0vn {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 186px;
  justify-content: flex-start;
  margin-right: 10.74px;
  margin-top: 14px;
  min-width: 649px;
  position: relative;
  width: auto;
}
.purchase .flex-row-C61RwL {
  align-items: center;
  align-self: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 20px;
  justify-content: flex-start;
  margin-left: 3.46px;
  min-width: 657px;
  position: relative;
  width: auto;
}
.purchase .flex-row-xpmbvu {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 150px;
  justify-content: flex-start;
  margin-right: 7px;
  margin-top: 30px;
  min-width: 585px;
  position: relative;
  width: auto;
}
.purchase .frame-1-ASQbkE {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  margin-right: 16.89px;
  margin-top: 33px;
  overflow: hidden;
  position: relative;
  width: 6px;
}
.purchase .frame-3-NG5cxJ {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 124px;
  justify-content: flex-end;
  left: 178px;
  min-width: 358px;
  padding: 6.8px 108.1px;
  position: absolute;
  top: 709px;
  -webkit-transform: rotate(-0.51deg);
          transform: rotate(-0.51deg);
  width: auto;
}
.purchase .frame-4-NG5cxJ {
  background-color: transparent;
  height: 4px;
  left: 374px;
  overflow: hidden;
  position: absolute;
  top: 737px;
  width: 4px;
}
.purchase .frame-5-AdpH7j {
  background-color: transparent;
  flex-shrink: 1;
  height: 100px;
  overflow: hidden;
  position: relative;
  -webkit-transform: rotate(0.50deg);
          transform: rotate(0.50deg);
  width: 100px;
}
.purchase .grotto-labs-logo-5-H5vPvf {
  background-color: transparent;
  height: 78px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 55px;
  width: 67px;
}
.purchase .jabbaform-purchase-button3-1-NG5cxJ {
  background-color: transparent;
  height: 260px;
  left: 106px;
  object-fit: cover;
  position: absolute;
  top: 611px;
  width: 524px;
  z-index:1;
}
.purchase .overlap-group-fKmFVR {
  background-color: transparent;
  flex-shrink: 1;
  height: 150px;
  position: relative;
  width: 538px;
}
.purchase .overlap-group1-0xy0vn {
  background-color: transparent;
  flex-shrink: 1;
  height: 871px;
  position: relative;
  width: 710px;
  margin-bottom:300px;
}
.purchase .overlap-group2-0xy0vn {
  background-color: transparent;
  flex-shrink: 1;
  height: 185px;
  margin-right: 0.18px;
  margin-top: 6px;
  position: relative;
  width: 616px;
}
.purchase .shiny-1-NG5cxJ {
  background-color: transparent;
  height: 54px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: 586px;
  width: 57px;
}
.purchase .shiny-2-xbkSRC {
  background-color: transparent;
  flex-shrink: 1;
  height: 88px;
  object-fit: cover;
  position: relative;
  width: 73px;
}
.purchase .shiny-3-xbkSRC {
  background-color: transparent;
  flex-shrink: 1;
  height: 54px;
  margin-right: 1.07px;
  margin-top: 36px;
  object-fit: cover;
  position: relative;
  width: 58px;
}
.purchase .shiny-4-NG5cxJ {
  background-color: transparent;
  height: 54px;
  left: 633px;
  object-fit: cover;
  position: absolute;
  top: 586px;
  width: 57px;
}
.purchase .shiny-5-y0Ojnz {
  background-color: transparent;
  flex-shrink: 1;
  height: 64px;
  margin-left: 3.89px;
  margin-top: 45px;
  object-fit: cover;
  position: relative;
  width: 66px;
}
.purchase .shiny-6-y0Ojnz {
  background-color: transparent;
  flex-shrink: 1;
  height: 86px;
  object-fit: cover;
  position: relative;
  width: 72px;
}
.purchase .x08-eth-1-52Wjwg {
  background-color: transparent;
  height: 93px;
  left: 346px;
  object-fit: cover;
  position: absolute;
  top: 74px;
  width: 244px;
}

.purchase .connect-wallet-52Wjwg {
  height: 93px;
  left: 346px;
  object-fit: cover;
  position: absolute;
  top: 74px;
  width: 244px;
}

.purchase .you-doodle202-9-t072157-z-1-NG5cxJ {
  background-color: transparent;
  height: 590px;
  left: 22px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 657px;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

img {
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
}

input[type="range"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image:  url("/img/j8-slider-track.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: auto;
    margin-top: 15px;
    margin-bottom: 15px;
    outline: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
    display:block;
    width:100%;
  }

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("/img/pickaxe-slider-handle.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    border: 0;
    border-radius: 0px;
    cursor: pointer;
    height: 100px;
    width: 100px;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    --thumb-size: 20;
}

input[type=range]::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("/img/pickaxe-slider-handle.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  border: 0;
  border-radius: 0px;
  cursor: pointer;
  height: 100px;
  width: 100px;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  --thumb-size: 20;
}






.slider-wrapper{
    width: 650px;
    position: relative;
    left: -600px;
    border: none;
    z-index: 100;
    background: none;
    top: 60px;
    height:100px;
}

/* screen - wallet-true */

.wallet-true {
  align-items: flex-end;
  background-color: var(--white);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: auto;
  min-height:1200px;
  justify-content: flex-start;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 33px 64.5px;
  position: relative;
  width: 789px;
}

.loading{
  background-color: transparent;
  flex-shrink: 1;
  height: 40px;
  margin-left: 190px;
  object-fit: cover;
  position: relative;
  width: 286px;
}

.wallet-true .asset-18-prov-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 15px;
  margin-bottom: 1.0px;
  margin-left: 42px;
  object-fit: cover;
  position: relative;
  width: 68px;
}
.wallet-true .asset-20-gallery-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 15px;
  margin-bottom: 1.0px;
  margin-left: 31px;
  object-fit: cover;
  position: relative;
  width: 85px;
}
.wallet-true .asset-21-purchase-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 17px;
  margin-bottom: 1.0px;
  margin-left: 49px;
  object-fit: cover;
  position: relative;
  width: 83px;
}
.wallet-true .asset-23-wallet-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 15px;
  margin-bottom: 1.0px;
  margin-left: 29px;
  object-fit: cover;
  position: relative;
  width: 99px;
}
.wallet-true .asset-23-wallet-2-GyJUNH {
  background-color: transparent;
  flex-shrink: 1;
  height: 40px;
  margin-left: 83px;
  object-fit: cover;
  position: relative;
  width: 286px;
}
.wallet-true .asset-26-j48-messy-1-GqJPlL {
  background-color: transparent;
  height: 120px;
  left: 196px;
  object-fit: cover;
  position: absolute;
  top: 31px;
  width: 190px;
}
.wallet-true .asset-28-bounty-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 20px;
  margin-left: 30px;
  object-fit: cover;
  position: relative;
  width: 88px;
}
.wallet-true .asset-3-l1-2-GqJPlL {
  background-color: transparent;
  height: 55px;
  left: 36px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 488px;
}
.wallet-true .asset-31-main-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 15px;
  margin-bottom: 1.0px;
  object-fit: cover;
  position: relative;
  width: 51px;
}
.wallet-true .asset-34-twitter-1-BISixz {
  background-color: transparent;
  flex-shrink: 1;
  height: 28px;
  margin-left: 14px;
  margin-top: 35.52px;
  object-fit: cover;
  position: relative;
  width: 33px;
}
.wallet-true .asset-35-discord-1-GqJPlL {
  background-color: transparent;
  height: 22px;
  left: 498px;
  object-fit: cover;
  position: absolute;
  top: 83px;
  width: 40px;
}
.wallet-true .asset-38-wallett-2-GyJUNH {
  background-color: transparent;
  flex-shrink: 1;
  height: 60px;
  margin-left: 83px;
  object-fit: cover;
  position: relative;
  width: 102px;
}
.wallet-true .asset-38-wallett-3-GyJUNH {
  background-color: transparent;
  flex-shrink: 1;
  height: 60px;
  object-fit: cover;
  position: relative;
  width: 102px;
}
.wallet-true .asset-41-grottolab1-1-GqJPlL {
  background-color: transparent;
  height: 48px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 60px;
  width: 58px;
}
.wallet-true .flex-col-C61RwL {
  align-items: flex-start;
  align-self: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 1.0px;
  margin-top: 65px;
  min-height: 2154px;
  position: relative;
  width: 656px;
}
.wallet-true .flex-row-0aLMTk {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 269px;
  justify-content: flex-start;
  margin-left: 25px;
  margin-top: 99px;
  min-width: 471px;
  position: relative;
  width: auto;
}
.wallet-true .flex-row-0xy0vn {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 60px;
  justify-content: flex-start;
  min-width: 656px;
  position: relative;
  width: auto;
}
.wallet-true .flex-row-4iD7zR {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 269px;
  justify-content: flex-start;
  margin-left: 25px;
  margin-top: 60px;
  min-width: 471px;
  position: relative;
  width: auto;
}
.wallet-true .flex-row-C61RwL {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 20px;
  justify-content: flex-start;
  min-width: 657px;
  position: relative;
  width: auto;
}
.wallet-true .flex-row-OtmKqf {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 269px;
  justify-content: flex-start;
  margin-left: 25px;
  margin-top: 41px;
  min-width: 452px;
  position: relative;
  width: auto;
}
.wallet-true .flex-row-exd5BQ {
  align-items: center;
  align-self: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 150px;
  justify-content: flex-start;
  margin-left: 31.0px;
  margin-top: 0px;
  min-width: 585px;
  position: relative;
  width: auto;
}
.wallet-true .flex-row-oV5UrE {
  align-items: flex-start;
  align-self: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 66px;
  justify-content: flex-start;
  margin-right: 3.0px;
  margin-top: 65px;
  min-width: 467px;
  position: relative;
  width: auto;
}
.wallet-true .flex-row-t6Autm {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 269px;
  justify-content: flex-start;
  margin-left: 25px;
  margin-top: 33px;
  min-width: 452px;
  position: relative;
  width: auto;
}
.wallet-true .flex-row-uPORJx {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 269px;
  justify-content: flex-start;
  margin-left: 25px;
  margin-top: 41px;
  min-width: 471px;
  position: relative;
  width: auto;
}
.wallet-true .frame-2-0xy0vn {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  margin-right: 92.44px;
  margin-top: 192px;
  overflow: hidden;
  position: relative;
  width: 6px;
}
.wallet-true .grotto-labs-logo-4-GqJPlL {
  background-color: transparent;
  height: 78px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  top: 55px;
  width: 67px;
}
.wallet-true .image-1-ossvxU {
  background-color: transparent;
  flex-shrink: 1;
  height: 269px;
  object-fit: cover;
  position: relative;
  width: 269px;
}
.wallet-true .image-2-gD87cZ {
  background-color: transparent;
  flex-shrink: 1;
  height: 269px;
  object-fit: cover;
  position: relative;
  width: 269px;
}
.wallet-true .image-3-RgyNpo {
  background-color: transparent;
  flex-shrink: 1;
  height: 269px;
  object-fit: cover;
  position: relative;
  width: 269px;
}
.wallet-true .image-4-xWQcfe {
  background-color: transparent;
  flex-shrink: 1;
  height: 269px;
  object-fit: cover;
  position: relative;
  width: 269px;
}
.wallet-true .image-5-QkTteQ {
  background-color: transparent;
  flex-shrink: 1;
  height: 62px;
  object-fit: cover;
  position: relative;
  width: 62px;
}
.wallet-true .image-6-O2UJZ2 {
  background-color: transparent;
  flex-shrink: 1;
  height: 269px;
  object-fit: cover;
  position: relative;
  width: 269px;
}
.wallet-true .j48-baform-0532-e-ganj48-baform-RgyNpo {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0.00px;
  line-height: normal;
  margin-left: 34px;
  margin-top: 51px;
  min-height: 44px;
  min-width: 168px;
  position: relative;
  text-align: left;
  width: auto;
}
.wallet-true .j48-baform-1986-type-legacyform-gD87cZ {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0.00px;
  line-height: normal;
  margin-left: 34px;
  margin-top: 51px;
  min-height: 44px;
  min-width: 149px;
  position: relative;
  text-align: left;
  width: auto;
}
.wallet-true .j48-baform-1988-type-legacyform-xWQcfe {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0.00px;
  line-height: normal;
  margin-left: 34px;
  margin-top: 51px;
  min-height: 44px;
  min-width: 149px;
  position: relative;
  text-align: left;
  width: auto;
}
.wallet-true .j48-baform-2397-e-ganj48-baform-ossvxU {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0.00px;
  line-height: normal;
  margin-left: 34px;
  margin-top: 51px;
  min-height: 44px;
  min-width: 168px;
  position: relative;
  text-align: left;
  width: auto;
}
.wallet-true .j48-baform-3652-e-ganj48-baform-O2UJZ2 {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0.00px;
  line-height: normal;
  margin-left: 34px;
  margin-top: 51px;
  min-height: 44px;
  min-width: 168px;
  position: relative;
  text-align: left;
  width: auto;
}
.wallet-true .overlap-group-BISixz {
  background-color: transparent;
  flex-shrink: 1;
  height: 150px;
  position: relative;
  width: 538px;
}
.wallet-true .your-wallet-0xc-d53-c511-a30631-QkTteQ {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0.00px;
  line-height: normal;
  margin-left: 17px;
  min-height: 44px;
  min-width: 388px;
  position: relative;
  text-align: left;
  width: auto;
}

/* screen - bounty */

.bounty {
  align-items: flex-end;
  background-color: var(--white);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 1194px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  padding: 33px 64.5px;
  position: relative;
  width: 789px;
}
.bounty .asset-18-prov-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 15px;
  margin-bottom: 1.0px;
  margin-left: 42px;
  object-fit: cover;
  position: relative;
  width: 68px;
}
.bounty .asset-20-gallery-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 15px;
  margin-bottom: 1.0px;
  margin-left: 31px;
  object-fit: cover;
  position: relative;
  width: 85px;
}
.bounty .asset-21-purchase-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 17px;
  margin-bottom: 1.0px;
  margin-left: 49px;
  object-fit: cover;
  position: relative;
  width: 83px;
}
.bounty .asset-23-wallet-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 15px;
  margin-bottom: 1.0px;
  margin-left: 29px;
  object-fit: cover;
  position: relative;
  width: 99px;
}
.bounty .asset-26-j48-messy-1-GVQFxU {
  background-color: transparent;
  height: 120px;
  left: 196px;
  object-fit: cover;
  position: absolute;
  top: 31px;
  width: 190px;
}
.bounty .asset-27-bountyframe-1-xXGW75 {
  background-color: transparent;
  height: 546px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 454px;
}
.bounty .asset-28-bounty-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 20px;
  margin-left: 30px;
  object-fit: cover;
  position: relative;
  width: 88px;
}
.bounty .asset-3-l1-2-GVQFxU {
  background-color: transparent;
  height: 55px;
  left: 36px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 488px;
}
.bounty .asset-30-claimbounty-1-FrjclV {
  align-self: center;
  background-color: transparent;
  flex-shrink: 1;
  height: 38px;
  margin-left: 53.0px;
  margin-top: 66px;
  object-fit: cover;
  position: relative;
  width: 255px;
  cursor: pointer;
}
.bounty .asset-31-main-1-dXBp89 {
  background-color: transparent;
  flex-shrink: 1;
  height: 15px;
  margin-bottom: 1.0px;
  object-fit: cover;
  position: relative;
  width: 51px;
}
.bounty .asset-34-twitter-1-BdpSxK {
  background-color: transparent;
  flex-shrink: 1;
  height: 28px;
  margin-bottom: 256px;
  margin-left: 14px;
  object-fit: cover;
  position: relative;
  width: 33px;
}
.bounty .asset-35-discord-1-GVQFxU {
  background-color: transparent;
  height: 22px;
  left: 498px;
  object-fit: cover;
  position: absolute;
  top: 83px;
  width: 40px;
}
.bounty .asset-41-grottolab1-1-GVQFxU {
  background-color: transparent;
  height: 48px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 60px;
  width: 58px;
}
.bounty .bountymerch-2-xXGW75 {
  background-color: transparent;
  height: 65px;
  left: 143px;
  object-fit: cover;
  position: absolute;
  top: 458px;
  width: 235px;
}
.bounty .bountymerch3-1-xXGW75 {
  background-color: transparent;
  height: 73px;
  left: 143px;
  object-fit: cover;
  position: absolute;
  top: 450px;
  width: 230px;
}

.bounty .do-you-have-oa-of-a-kind-prize-FrjclV {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0.00px;
  line-height: normal;
  margin-left: 17.0px;
  margin-top: 40px;
  min-height: 66px;
  min-width: 425px;
  position: relative;
  text-align: left;
  width: auto;
}

.bounty .flex-col-BdpSxK {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 1241px;
  position: relative;
  width: 538px;
}
.bounty .flex-row-C61RwL {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 20px;
  justify-content: flex-start;
  min-width: 657px;
  position: relative;
  width: auto;
}
.bounty .flex-row-VMr6Om {
  align-items: flex-end;
  align-self: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 1241px;
  justify-content: flex-start;
  margin-top: 91px;
  min-width: 585px;
  position: relative;
  width: auto;
}

.bounty .frame-1-FrjclV {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  margin-right: 22.44px;
  margin-top: 18px;
  overflow: hidden;
  position: relative;
  width: 6px;
}
.bounty .frame-2-FrjclV {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  margin-right: 25.44px;
  margin-top: 192px;
  overflow: hidden;
  position: relative;
  width: 6px;
}
.bounty .grotto-labs-logo-3-GVQFxU {
  background-color: transparent;
  height: 78px;
  left: 1px;
  object-fit: cover;
  position: absolute;
  top: 52px;
  width: 67px;
}
.bounty .j48-balegacy-165-1-xXGW75 {
  background-color: transparent;
  height: 210px;
  left: 109px;
  object-fit: cover;
  position: absolute;
  top: 168px;
  width: 210px;
}
.bounty .left-arrow-1-xXGW75 {
  background-color: transparent;
  height: 134px;
  left: 50px;
  object-fit: cover;
  position: absolute;
  top: 189px;
  width: 56px;
  z-index:2;
  cursor: pointer;
}
.bounty .overlap-group-FrjclV {
  background-color: transparent;
  flex-shrink: 1;
  height: 546px;
  margin-right: 20px;
  position: relative;
  width: 454px;
}
.bounty .overlap-group1-FrjclV {
  background-color: transparent;
  flex-shrink: 1;
  height: 150px;
  margin-top: 32px;
  position: relative;
  width: 538px;
}
.bounty .right-arrow-1-xXGW75 {
  background-color: transparent;
  height: 133px;
  left: 344px;
  object-fit: cover;
  position: absolute;
  top: 192px;
  width: 57px;
  z-index:2;
  cursor: pointer;
}

/* screen - formgallery */

.formgallery {
  background-color: var(--white);
  height: 4480px;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 789px;
  margin-top:-100px;
  
}

.musiform-video{
  width:480px;
  height:300px;
  margin:10px auto;
}

.musiform-video img{
  object-fit: cover;
}
.musiform-video video {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.formgallery .asset-18-prov-2-C61RwL {
  background-color: transparent;
  height: 15px;
  left: 649px;
  object-fit: cover;
  position: absolute;
  top: 35px;
  width: 68px;
}
.formgallery .asset-20-gallery-2-C61RwL {
  background-color: transparent;
  height: 15px;
  left: 522px;
  object-fit: cover;
  position: absolute;
  top: 37px;
  width: 85px;
}
.formgallery .asset-21-purchase-2-C61RwL {
  background-color: transparent;
  height: 17px;
  left: 160px;
  object-fit: cover;
  position: absolute;
  top: 35px;
  width: 83px;
}
.formgallery .asset-23-wallet-3-C61RwL {
  background-color: transparent;
  height: 15px;
  left: 273px;
  object-fit: cover;
  position: absolute;
  top: 36px;
  width: 99px;
}
.formgallery .asset-26-j48-messy-1-C61RwL {
  background-color: transparent;
  height: 120px;
  left: 289px;
  object-fit: cover;
  position: absolute;
  top: 4365px;
  width: 190px;
}
.formgallery .asset-28-bounty-2-C61RwL {
  background-color: transparent;
  height: 20px;
  left: 402px;
  object-fit: cover;
  position: absolute;
  top: 35px;
  width: 88px;
}
.formgallery .asset-3-l1-2-C61RwL {
  background-color: transparent;
  height: 55px;
  left: 129px;
  object-fit: cover;
  position: absolute;
  top: 4334px;
  width: 488px;
}
.formgallery .asset-31-main-2-C61RwL {
  background-color: transparent;
  height: 15px;
  left: 60px;
  object-fit: cover;
  position: absolute;
  top: 35px;
  width: 51px;
}
.formgallery .asset-34-twitter-1-C61RwL {
  background-color: transparent;
  height: 28px;
  left: 645px;
  object-fit: cover;
  position: absolute;
  top: 4413px;
  width: 33px;
}
.formgallery .asset-35-discord-1-C61RwL {
  background-color: transparent;
  height: 22px;
  left: 591px;
  object-fit: cover;
  position: absolute;
  top: 4417px;
  width: 40px;
}
.formgallery .asset-41-grottolab1-1-C61RwL {
  background-color: transparent;
  height: 48px;
  left: 93px;
  object-fit: cover;
  position: absolute;
  top: 4394px;
  width: 58px;
}
.formgallery .bwgan-142-1-C61RwL {
  background-color: transparent;
  height: 153px;
  left: 78px;
  object-fit: cover;
  position: absolute;
  top: 1369px;
  width: 164px;
}
.formgallery .bwgan-37-C61RwL {
  background-color: transparent;
  height: 168px;
  left: 540px;
  object-fit: cover;
  position: absolute;
  top: 1384px;
  width: 144px;
}
.formgallery .bwgan-55-C61RwL {
  background-color: transparent;
  height: 122px;
  left: 390px;
  object-fit: cover;
  position: absolute;
  top: 1400px;
  width: 128px;
}
.formgallery .bwgan-73-C61RwL {
  background-color: transparent;
  height: 145px;
  left: 267px;
  object-fit: cover;
  position: absolute;
  top: 1384px;
  width: 98px;
}
.formgallery .colorgan-10-C61RwL {
  background-color: transparent;
  height: 155px;
  left: 388px;
  object-fit: cover;
  position: absolute;
  top: 1529px;
  width: 141px;
}
.formgallery .colorgan-26-C61RwL {
  background-color: transparent;
  height: 152px;
  left: 251px;
  object-fit: cover;
  position: absolute;
  top: 1529px;
  width: 144px;
}
.formgallery .colorgan-29-C61RwL {
  background-color: transparent;
  height: 132px;
  left: 100px;
  object-fit: cover;
  position: absolute;
  top: 1536px;
  width: 124px;
}
.formgallery .colorgan-8-C61RwL {
  background-color: transparent;
  height: 152px;
  left: 537px;
  object-fit: cover;
  position: absolute;
  top: 1529px;
  width: 144px;
}
.formgallery .corruptgan-12-C61RwL {
  background-color: transparent;
  height: 136px;
  left: 240px;
  object-fit: cover;
  position: absolute;
  top: 1688px;
  width: 148px;
}
.formgallery .corruptgan-16-1-C61RwL {
  background-color: transparent;
  height: 148px;
  left: 87px;
  object-fit: cover;
  position: absolute;
  top: 1681px;
  width: 152px;
}
.formgallery .corruptgan-8-C61RwL {
  background-color: transparent;
  height: 136px;
  left: 534px;
  object-fit: cover;
  position: absolute;
  top: 1688px;
  width: 147px;
}
.formgallery .corruptgan-9-C61RwL {
  background-color: transparent;
  height: 136px;
  left: 388px;
  object-fit: cover;
  position: absolute;
  top: 1688px;
  width: 146px;
}
.formgallery .frame-2-C61RwL {
  background-color: transparent;
  height: 1px;
  left: 555px;
  overflow: hidden;
  position: absolute;
  top: 1012px;
  width: 5px;
}
.formgallery .ganj48-baform-g-ettings-applied-C61RwL {
  background-color: transparent;
  height: auto;
  left: 116px;
  letter-spacing: 0.00px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 1197px;
  width: 532px;
}
.formgallery .greyscalelegacy-114-C61RwL {
  background-color: transparent;
  height: 148px;
  left: 376px;
  object-fit: cover;
  position: absolute;
  top: 876px;
  width: 146px;
}
.formgallery .greyscalelegacy-288-C61RwL {
  background-color: transparent;
  height: 148px;
  left: 232px;
  object-fit: cover;
  position: absolute;
  top: 876px;
  width: 144px;
}
.formgallery .greyscalelegacy-347-C61RwL {
  background-color: transparent;
  height: 148px;
  left: 86px;
  object-fit: cover;
  position: absolute;
  top: 876px;
  width: 146px;
}
.formgallery .greyscalelegacy-97-C61RwL {
  background-color: transparent;
  height: 148px;
  left: 522px;
  object-fit: cover;
  position: absolute;
  top: 876px;
  width: 144px;
}
.formgallery .grotto-labs-logo-3-C61RwL {
  background-color: transparent;
  height: 78px;
  left: 88px;
  object-fit: cover;
  position: absolute;
  top: 4386px;
  width: 67px;
}
.formgallery .hallucinogen-11-C61RwL {
  background-color: transparent;
  height: 154px;
  left: 86px;
  object-fit: cover;
  position: absolute;
  top: 2154px;
  width: 162px;
}
.formgallery .hallucinogen-2-C61RwL {
  background-color: transparent;
  height: 154px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 2154px;
  width: 153px;
}
.formgallery .hallucinogen-8-C61RwL {
  background-color: transparent;
  height: 154px;
  left: 397px;
  object-fit: cover;
  position: absolute;
  top: 2154px;
  width: 151px;
}
.formgallery .hallucinogen-9-C61RwL {
  background-color: transparent;
  height: 154px;
  left: 246px;
  object-fit: cover;
  position: absolute;
  top: 2154px;
  width: 153px;
}
.formgallery .left-arrow-1-C61RwL {
  background-color: transparent;
  height: 336px;
  left: 75px;
  object-fit: cover;
  position: absolute;
  top: 184px;
  width: 138px;
}
.formgallery .legacyform-lega-ries-in-the-mix-C61RwL {
  background-color: transparent;
  height: auto;
  left: 116px;
  letter-spacing: 0.00px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 559px;
  width: 532px;
}
.formgallery .legacymonochrome-14-C61RwL {
  background-color: transparent;
  height: 139px;
  left: 521px;
  object-fit: cover;
  position: absolute;
  top: 720px;
  width: 144px;
}
.formgallery .legacymonochrome-35-C61RwL {
  background-color: transparent;
  height: 139px;
  left: 377px;
  object-fit: cover;
  position: absolute;
  top: 725px;
  width: 143px;
}
.formgallery .legacymonochrome-39-C61RwL {
  background-color: transparent;
  height: 139px;
  left: 232px;
  object-fit: cover;
  position: absolute;
  top: 732px;
  width: 144px;
}
.formgallery .legacymonochrome-50-C61RwL {
  background-color: transparent;
  height: 139px;
  left: 90px;
  object-fit: cover;
  position: absolute;
  top: 725px;
  width: 143px;
}
.formgallery .legacymulticolor-11-1-C61RwL {
  background-color: transparent;
  height: 123px;
  left: 237px;
  object-fit: cover;
  position: absolute;
  top: 1042px;
  width: 137px;
}
.formgallery .legacymulticolor-26-1-C61RwL {
  background-color: transparent;
  height: 129px;
  left: 385px;
  object-fit: cover;
  position: absolute;
  top: 1042px;
  width: 144px;
}
.formgallery .legacymulticolor-31-1-C61RwL {
  background-color: transparent;
  height: 155px;
  left: 534px;
  object-fit: cover;
  position: absolute;
  top: 1031px;
  width: 150px;
}
.formgallery .legacymulticolor-5-1-C61RwL {
  background-color: transparent;
  height: 159px;
  left: 78px;
  object-fit: cover;
  position: absolute;
  top: 1020px;
  width: 161px;
}
.formgallery .motionform-moti-htly-undulating-C61RwL {
  background-color: transparent;
  height: auto;
  left: 116px;
  letter-spacing: 0.00px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 1878px;
  width: 532px;
}
.formgallery .musiform-musifo-ely-as-possible-C61RwL {
  background-color: transparent;
  height: auto;
  left: 87px;
  letter-spacing: 0.00px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 2367px;
  width: 532px;
}
.formgallery .need-to-insert-mp4-assets-here-C61RwL {
  background-color: transparent;
  color: var(--black);
  font-family: 'Space Mono', Helvetica;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  left: 155px;
  letter-spacing: 0.00px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 2612px;
  width: auto;
}

button, input{
  display: contents;
}

.alice-carousel{
  width:360px !important;
}

.alice-carousel__stage {
height:600px !important;
}

.alice-carousel__stage-item{
  background-color: transparent;
  position: absolute;
  top: 184px;
  height: 344px;
  object-fit: cover;
  width: 343px;
}

.gallery-item img{
  height: 344px;
  object-fit: cover;
  width: 343px;
}

.gallery-item video{
  height: 344px;
  object-fit: cover;
  width: 343px;
}

.alice-carousel__dots{
  display:none;
}
.alice-carousel__prev-btn-wrapper{
  display:none;
}
.alice-carousel__next-btn-wrapper{
  display:none;
}


.formgallery .rectangle-4-C61RwL {
  background-color: transparent;
  height: 344px;
  left: 216px;
  object-fit: cover;
  position: absolute;
  top: 184px;
  width: 343px;
}
.formgallery .rectangle-6-C61RwL {
  background-color: white;
  height: 100px;
  left: 86px;
  position: absolute;
  top: 2541px;
  width: 618px;
}
.formgallery .right-arrow-1-C61RwL {
  background-color: transparent;
  height: 336px;
  left: 575px;
  object-fit: cover;
  position: absolute;
  top: 184px;
  width: 116px;
}
.formgallery .slice-1-C61RwL {
  background-color: transparent;
  height: 100px;
  left: 4344px;
  position: absolute;
  top: 938px;
  width: 100px;
}
.formgallery .span0-9xwOVZ {
  color: rgba(0,0,0,1.0);
  font-family: 'Space Mono', Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
.formgallery .span0-CsDQ4b {
  color: rgba(0,0,0,1.0);
  font-family: 'Space Mono', Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
.formgallery .span0-KE05Ey {
  color: rgba(0,0,0,1.0);
  font-family: 'Space Mono', Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
.formgallery .span0-PdQC4f {
  color: rgba(0,0,0,1.0);
  font-family: 'Space Mono', Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
.formgallery .span0-lwroS2 {
  color: rgba(0,0,0,1.0);
  font-family: 'Space Mono', Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
.formgallery .span1-9xwOVZ {
  color: rgba(0,0,0,1.0);
  font-family: 'Space Mono', Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.formgallery .span1-CsDQ4b {
  color: rgba(0,0,0,1.0);
  font-family: 'Space Mono', Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.formgallery .span1-KE05Ey {
  color: rgba(0,0,0,1.0);
  font-family: 'Space Mono', Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.formgallery .span1-PdQC4f {
  color: rgba(0,0,0,1.0);
  font-family: 'Space Mono', Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.formgallery .span1-lwroS2 {
  color: rgba(0,0,0,1.0);
  font-family: 'Space Mono', Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.formgallery .speedie-1-C61RwL {
  background-color: transparent;
  height: 143px;
  left: 547px;
  object-fit: cover;
  position: absolute;
  top: 2014px;
  width: 152px;
}
.formgallery .speedie-109-C61RwL {
  background-color: transparent;
  height: 143px;
  left: 92px;
  object-fit: cover;
  position: absolute;
  top: 2014px;
  width: 151px;
}
.formgallery .speedie-78-C61RwL {
  background-color: transparent;
  height: 143px;
  left: 395px;
  object-fit: cover;
  position: absolute;
  top: 2014px;
  width: 152px;
}
.formgallery .speedie-90-C61RwL {
  background-color: transparent;
  height: 143px;
  left: 243px;
  object-fit: cover;
  position: absolute;
  top: 2014px;
  width: 152px;
}
.formgallery .text-C61RwL {
  background-color: transparent;
  color: var(--black);
  font-family: 'Space Mono', Helvetica;
  font-size: 96px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  left: 5828px;
  letter-spacing: 0.00px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 1980px;
  width: auto;
}
.formgallery .ultraform-ultra-ization-is-nice-C61RwL {
  background-color: transparent;
  height: auto;
  left: 86px;
  letter-spacing: 0.00px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 3762px;
  width: 608px;
}
.formgallery .ultraformchroma010-C61RwL {
  background-color: transparent;
  height: 150px;
  left: 552px;
  object-fit: cover;
  position: absolute;
  top: 3910px;
  width: 154px;
}
.formgallery .ultraformchroma011-C61RwL {
  background-color: transparent;
  height: 150px;
  left: 396px;
  object-fit: cover;
  position: absolute;
  top: 3910px;
  width: 156px;
}
.formgallery .ultraformchroma012-C61RwL {
  background-color: transparent;
  height: 150px;
  left: 240px;
  object-fit: cover;
  position: absolute;
  top: 3910px;
  width: 156px;
}
.formgallery .ultraformchroma014-C61RwL {
  background-color: transparent;
  height: 150px;
  left: 84px;
  object-fit: cover;
  position: absolute;
  top: 3910px;
  width: 156px;
}
.formgallery .ultraformpixel007-C61RwL {
  background-color: transparent;
  height: 138px;
  left: 551px;
  object-fit: cover;
  position: absolute;
  top: 4080px;
  width: 159px;
}
.formgallery .ultraformpixel009-C61RwL {
  background-color: transparent;
  height: 138px;
  left: 391px;
  object-fit: cover;
  position: absolute;
  top: 4080px;
  width: 160px;
}
.formgallery .ultraformpixel015-C61RwL {
  background-color: transparent;
  height: 138px;
  left: 231px;
  object-fit: cover;
  position: absolute;
  top: 4080px;
  width: 159px;
}
.formgallery .ultraformpixel020-C61RwL {
  background-color: transparent;
  height: 138px;
  left: 69px;
  object-fit: cover;
  position: absolute;
  top: 4080px;
  width: 160px;
}

/* screen - about */

.about {
  align-items: flex-end;
  background-color: var(--white);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 873px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  padding: 33px 64.5px;
  position: relative;
  width: 789px;
}
.about .asset-18-prov-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 15px;
  margin-bottom: 1.0px;
  margin-left: 42px;
  object-fit: cover;
  position: relative;
  width: 68px;
}
.about .asset-20-gallery-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 15px;
  margin-bottom: 1.0px;
  margin-left: 31px;
  object-fit: cover;
  position: relative;
  width: 85px;
}
.about .asset-21-purchase-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 17px;
  margin-bottom: 1.0px;
  margin-left: 49px;
  object-fit: cover;
  position: relative;
  width: 83px;
}
.about .asset-23-wallet-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 15px;
  margin-bottom: 1.0px;
  margin-left: 29px;
  object-fit: cover;
  position: relative;
  width: 99px;
}
.about .asset-26-j48-messy-1-GVQFxU {
  background-color: transparent;
  height: 120px;
  left: 197px;
  object-fit: cover;
  position: absolute;
  top: 31px;
  width: 190px;
}
.about .asset-28-bounty-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 20px;
  margin-left: 30px;
  object-fit: cover;
  position: relative;
  width: 88px;
}
.about .asset-3-l1-2-GVQFxU {
  background-color: transparent;
  height: 55px;
  left: 37px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 488px;
}
.about .asset-31-main-1-dXBp89 {
  background-color: transparent;
  flex-shrink: 1;
  height: 15px;
  margin-bottom: 1.0px;
  object-fit: cover;
  position: relative;
  width: 51px;
}
.about .asset-34-twitter-1-BdpSxK {
  background-color: transparent;
  flex-shrink: 1;
  height: 28px;
  margin-bottom: 237px;
  margin-left: 14px;
  object-fit: cover;
  position: relative;
  width: 33px;
}
.about .asset-35-discord-1-GVQFxU {
  background-color: transparent;
  height: 22px;
  left: 499px;
  object-fit: cover;
  position: absolute;
  top: 83px;
  width: 40px;
}
.about .asset-41-grottolab1-1-GVQFxU {
  background-color: transparent;
  height: 48px;
  left: 1px;
  object-fit: cover;
  position: absolute;
  top: 60px;
  width: 58px;
}
.about .asset-41-grottolab1-2-xXGW75 {
  background-color: transparent;
  height: 179px;
  left: 21px;
  object-fit: cover;
  position: absolute;
  top: 18px;
  width: 217px;
}
.about .flex-col-BdpSxK {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 990px;
  position: relative;
  width: 539px;
}
.about .flex-row-C61RwL {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 20px;
  justify-content: flex-start;
  min-width: 657px;
  position: relative;
  width: auto;
}
.about .flex-row-VMr6Om {
  align-items: flex-end;
  align-self: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 990px;
  justify-content: flex-start;
  margin-left: 17.0px;
  margin-top: 18px;
  min-width: 586px;
  position: relative;
  width: auto;
}
.about .frame-2-FrjclV {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  margin-right: 25.44px;
  margin-top: 192px;
  overflow: hidden;
  position: relative;
  width: 6px;
}
.about .grotto-labs-are-msprotonmailcom-FrjclV {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0.00px;
  line-height: normal;
  margin-right: 22px;
  margin-top: 11px;
  min-height: 384px;
  position: relative;
  text-align: center;
  width: 450px;
}
.about .grotto-labs-logo-1-xXGW75 {
  background-color: transparent;
  height: 216px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 261px;
}
.about .grotto-labs-logo-2-GVQFxU {
  background-color: transparent;
  height: 78px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 55px;
  width: 67px;
}
.about .overlap-group-FrjclV {
  align-self: center;
  background-color: transparent;
  flex-shrink: 1;
  height: 216px;
  margin-left: 30.0px;
  position: relative;
  width: 261px;
}
.about .overlap-group1-FrjclV {
  background-color: transparent;
  flex-shrink: 1;
  height: 150px;
  margin-top: 35px;
  position: relative;
  width: 539px;
}

/* screen - grid */

.container-center-horizontal{
  margin-top:0px;
}

.blocker{
  position:fixed;
  top:0px;
  left:0px;
  height:60px;
  width:100%;
  background:white;
  z-index:1;
}

.slider {
  -webkit-animation: marquee 60s linear infinite;
          animation: marquee 60s linear infinite;
}

@-webkit-keyframes marquee {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  to { 
    -webkit-transform: translateY(0); 
            transform: translateY(0);
  }
}

@keyframes marquee {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  to { 
    -webkit-transform: translateY(0); 
            transform: translateY(0);
  }
}

.grid {
  background-color: var(--white);
  height: 3500px;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 780px;
  margin-top:-50px;
  display:block;
}

.grid .asset-18-prov-2-C61RwL {
  background-color: transparent;
  cursor: pointer;
  height: 15px;
  left: 649px;
  object-fit: cover;
  position: absolute;
  top: 35px;
  width: 68px;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation: pulse 3s infinite;
          animation: pulse 3s infinite;

}
.grid .asset-20-gallery-2-C61RwL {
  background-color: transparent;
  cursor: pointer;
  height: 15px;
  left: 522px;
  object-fit: cover;
  position: absolute;
  top: 37px;
  width: 85px;
}
.grid .asset-21-purchase-2-C61RwL {
  background-color: transparent;
  cursor: pointer;
  height: 17px;
  left: 160px;
  object-fit: cover;
  position: absolute;
  top: 35px;
  width: 83px;
}
.grid .asset-23-wallet-3-C61RwL {
  background-color: transparent;
  cursor: pointer;
  height: 15px;
  left: 273px;
  object-fit: cover;
  position: absolute;
  top: 36px;
  width: 99px;
}
.grid .asset-26-j48-messy-1-C61RwL {
  background-color: transparent;
  height: 120px;
  left: 294px;
  object-fit: cover;
  position: absolute;
  top: 3198px;
  width: 190px;
}
.grid .asset-28-bounty-2-C61RwL {
  background-color: transparent;
  height: 20px;
  left: 402px;
  object-fit: cover;
  position: absolute;
  top: 35px;
  width: 88px;
}
.grid .asset-3-l1-2-C61RwL {
  background-color: transparent;
  height: 55px;
  left: 134px;
  object-fit: cover;
  position: absolute;
  top: 3167px;
  width: 488px;
}
.grid .asset-31-main-2-C61RwL {
  background-color: transparent;
  cursor: pointer;
  height: 15px;
  left: 60px;
  object-fit: cover;
  position: absolute;
  top: 35px;
  width: 51px;
}
.grid .asset-34-twitter-1-C61RwL {
  background-color: transparent;
  height: 28px;
  left: 650px;
  object-fit: cover;
  position: absolute;
  top: 3246px;
  width: 33px;
}
.grid .asset-35-discord-1-C61RwL {
  background-color: transparent;
  height: 22px;
  left: 596px;
  object-fit: cover;
  position: absolute;
  top: 3250px;
  width: 40px;
}
.grid .asset-41-grottolab1-1-C61RwL {
  background-color: transparent;
  height: 48px;
  left: 98px;
  object-fit: cover;
  position: absolute;
  top: 3227px;
  width: 58px;
}
.grid .coloredgans1-150-C61RwL {
  background-color: transparent;
  height: 111px;
  left: 1052px;
  object-fit: cover;
  position: absolute;
  top: 2843px;
  width: 111px;
}
.grid .coloredgans1-152-C61RwL {
  background-color: transparent;
  height: 111px;
  left: 944px;
  object-fit: cover;
  position: absolute;
  top: 2838px;
  width: 111px;
}
.grid .coloredgans1-154-C61RwL {
  background-color: transparent;
  height: 111px;
  left: 830px;
  object-fit: cover;
  position: absolute;
  top: 2838px;
  width: 111px;
}
.grid .greyscalelegacy-1-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 2150px;
  width: 106px;
}
.grid .greyscalelegacy-1-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 2037px;
  width: 106px;
}
.grid .greyscalelegacy-100-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 1937px;
  width: 107px;
}
.grid .greyscalelegacy-100-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 1824px;
  width: 106px;
}
.grid .greyscalelegacy-101-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 1937px;
  width: 106px;
}
.grid .greyscalelegacy-101-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 1824px;
  width: 106px;
}
.grid .greyscalelegacy-102-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 1937px;
  width: 106px;
}
.grid .greyscalelegacy-102-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 1824px;
  width: 107px;
}
.grid .greyscalelegacy-103-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 2052px;
  width: 107px;
}
.grid .greyscalelegacy-103-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 1938px;
  width: 107px;
}
.grid .greyscalelegacy-104-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 2052px;
  width: 106px;
}
.grid .greyscalelegacy-104-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 1938px;
  width: 106px;
}
.grid .greyscalelegacy-105-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 2052px;
  width: 107px;
}
.grid .greyscalelegacy-105-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 1938px;
  width: 107px;
}
.grid .greyscalelegacy-106-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 2044px;
  width: 106px;
}
.grid .greyscalelegacy-106-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 1930px;
  width: 106px;
}
.grid .greyscalelegacy-107-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 2044px;
  width: 107px;
}
.grid .greyscalelegacy-107-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 1930px;
  width: 107px;
}
.grid .greyscalelegacy-108-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 2044px;
  width: 106px;
}
.grid .greyscalelegacy-108-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 1930px;
  width: 106px;
}
.grid .greyscalelegacy-109-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 2044px;
  width: 107px;
}
.grid .greyscalelegacy-109-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 1930px;
  width: 107px;
}
.grid .greyscalelegacy-110-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 2044px;
  width: 107px;
}
.grid .greyscalelegacy-110-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 1930px;
  width: 106px;
}
.grid .greyscalelegacy-111-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 2044px;
  width: 106px;
}
.grid .greyscalelegacy-111-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 1930px;
  width: 106px;
}
.grid .greyscalelegacy-112-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 2044px;
  width: 106px;
}
.grid .greyscalelegacy-112-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 1930px;
  width: 107px;
}
.grid .greyscalelegacy-113-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 2158px;
  width: 107px;
}
.grid .greyscalelegacy-113-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 2045px;
  width: 107px;
}
.grid .greyscalelegacy-114-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 2158px;
  width: 106px;
}
.grid .greyscalelegacy-114-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 2045px;
  width: 106px;
}
.grid .greyscalelegacy-115-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 2158px;
  width: 107px;
}
.grid .greyscalelegacy-115-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 2045px;
  width: 107px;
}
.grid .greyscalelegacy-119-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 2150px;
  width: 106px;
}
.grid .greyscalelegacy-119-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 2037px;
  width: 106px;
}
.grid .greyscalelegacy-123-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 2150px;
  width: 107px;
}
.grid .greyscalelegacy-123-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 2037px;
  width: 107px;
}
.grid .greyscalelegacy-132-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 1732px;
  width: 107px;
}
.grid .greyscalelegacy-132-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 1619px;
  width: 107px;
}
.grid .greyscalelegacy-133-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 1724px;
  width: 106px;
}
.grid .greyscalelegacy-133-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 1611px;
  width: 106px;
}
.grid .greyscalelegacy-135-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 1724px;
  width: 107px;
}
.grid .greyscalelegacy-135-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 1611px;
  width: 107px;
}
.grid .greyscalelegacy-139-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 1724px;
  width: 106px;
}
.grid .greyscalelegacy-139-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 1611px;
  width: 106px;
}
.grid .greyscalelegacy-140-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 1724px;
  width: 107px;
}
.grid .greyscalelegacy-140-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 1611px;
  width: 107px;
}
.grid .greyscalelegacy-141-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 1724px;
  width: 107px;
}
.grid .greyscalelegacy-141-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 1611px;
  width: 106px;
}
.grid .greyscalelegacy-142-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 1724px;
  width: 106px;
}
.grid .greyscalelegacy-142-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 1611px;
  width: 106px;
}
.grid .greyscalelegacy-143-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 1724px;
  width: 106px;
}
.grid .greyscalelegacy-143-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 1611px;
  width: 107px;
}
.grid .greyscalelegacy-144-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 1839px;
  width: 107px;
}
.grid .greyscalelegacy-144-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 1725px;
  width: 107px;
}
.grid .greyscalelegacy-145-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 1839px;
  width: 106px;
}
.grid .greyscalelegacy-145-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 1725px;
  width: 106px;
}
.grid .greyscalelegacy-146-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 1839px;
  width: 107px;
}
.grid .greyscalelegacy-146-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 1725px;
  width: 107px;
}
.grid .greyscalelegacy-147-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 1831px;
  width: 106px;
}
.grid .greyscalelegacy-147-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 1717px;
  width: 106px;
}
.grid .greyscalelegacy-148-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 1831px;
  width: 107px;
}
.grid .greyscalelegacy-148-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 1717px;
  width: 107px;
}
.grid .greyscalelegacy-149-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 1831px;
  width: 106px;
}
.grid .greyscalelegacy-149-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 1717px;
  width: 106px;
}
.grid .greyscalelegacy-150-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 1831px;
  width: 107px;
}
.grid .greyscalelegacy-150-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 1717px;
  width: 107px;
}
.grid .greyscalelegacy-151-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 1831px;
  width: 107px;
}
.grid .greyscalelegacy-151-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 1717px;
  width: 106px;
}
.grid .greyscalelegacy-152-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 1831px;
  width: 106px;
}
.grid .greyscalelegacy-152-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 1717px;
  width: 106px;
}
.grid .greyscalelegacy-153-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 1831px;
  width: 106px;
}
.grid .greyscalelegacy-153-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 1717px;
  width: 107px;
}
.grid .greyscalelegacy-154-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 1307px;
  width: 107px;
}
.grid .greyscalelegacy-154-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 1192px;
  width: 107px;
}
.grid .greyscalelegacy-155-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 1299px;
  width: 106px;
}
.grid .greyscalelegacy-155-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 1184px;
  width: 106px;
}
.grid .greyscalelegacy-156-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 1299px;
  width: 107px;
}
.grid .greyscalelegacy-156-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 1184px;
  width: 107px;
}
.grid .greyscalelegacy-157-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 1299px;
  width: 106px;
}
.grid .greyscalelegacy-157-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 1184px;
  width: 106px;
}
.grid .greyscalelegacy-158-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 1299px;
  width: 107px;
}
.grid .greyscalelegacy-158-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 1184px;
  width: 107px;
}
.grid .greyscalelegacy-159-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 1299px;
  width: 107px;
}
.grid .greyscalelegacy-159-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 1184px;
  width: 106px;
}
.grid .greyscalelegacy-160-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 1299px;
  width: 106px;
}
.grid .greyscalelegacy-160-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 1184px;
  width: 106px;
}
.grid .greyscalelegacy-161-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 1299px;
  width: 106px;
}
.grid .greyscalelegacy-161-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 1184px;
  width: 107px;
}
.grid .greyscalelegacy-162-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 1413px;
  width: 107px;
}
.grid .greyscalelegacy-162-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 1299px;
  width: 107px;
}
.grid .greyscalelegacy-163-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 1413px;
  width: 106px;
}
.grid .greyscalelegacy-163-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 1299px;
  width: 106px;
}
.grid .greyscalelegacy-164-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 1413px;
  width: 107px;
}
.grid .greyscalelegacy-164-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 1299px;
  width: 107px;
}
.grid .greyscalelegacy-165-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 1405px;
  width: 106px;
}
.grid .greyscalelegacy-165-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 1291px;
  width: 106px;
}
.grid .greyscalelegacy-173-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 1405px;
  width: 107px;
}
.grid .greyscalelegacy-173-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 1291px;
  width: 107px;
}
.grid .greyscalelegacy-179-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 1405px;
  width: 106px;
}
.grid .greyscalelegacy-179-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 1291px;
  width: 106px;
}
.grid .greyscalelegacy-181-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 1405px;
  width: 107px;
}
.grid .greyscalelegacy-181-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 1291px;
  width: 107px;
}
.grid .greyscalelegacy-183-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 1405px;
  width: 107px;
}
.grid .greyscalelegacy-183-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 1291px;
  width: 106px;
}
.grid .greyscalelegacy-184-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 1405px;
  width: 106px;
}
.grid .greyscalelegacy-184-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 1291px;
  width: 106px;
}
.grid .greyscalelegacy-188-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 1405px;
  width: 106px;
}
.grid .greyscalelegacy-188-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 1291px;
  width: 107px;
}
.grid .greyscalelegacy-190-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 1519px;
  width: 107px;
}
.grid .greyscalelegacy-190-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 1406px;
  width: 107px;
}
.grid .greyscalelegacy-193-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 1519px;
  width: 106px;
}
.grid .greyscalelegacy-193-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 1406px;
  width: 106px;
}
.grid .greyscalelegacy-195-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 1519px;
  width: 107px;
}
.grid .greyscalelegacy-195-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 1406px;
  width: 107px;
}
.grid .greyscalelegacy-198-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 1511px;
  width: 106px;
}
.grid .greyscalelegacy-198-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 1398px;
  width: 106px;
}
.grid .greyscalelegacy-199-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 1511px;
  width: 107px;
}
.grid .greyscalelegacy-199-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 1398px;
  width: 107px;
}
.grid .greyscalelegacy-2-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 2150px;
  width: 107px;
}
.grid .greyscalelegacy-2-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 2037px;
  width: 107px;
}
.grid .greyscalelegacy-200-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 1511px;
  width: 106px;
}
.grid .greyscalelegacy-200-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 1398px;
  width: 106px;
}
.grid .greyscalelegacy-201-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 1511px;
  width: 107px;
}
.grid .greyscalelegacy-201-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 1398px;
  width: 107px;
}
.grid .greyscalelegacy-202-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 1511px;
  width: 107px;
}
.grid .greyscalelegacy-202-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 1398px;
  width: 106px;
}
.grid .greyscalelegacy-203-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 1511px;
  width: 106px;
}
.grid .greyscalelegacy-203-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 1398px;
  width: 106px;
}
.grid .greyscalelegacy-204-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 1511px;
  width: 106px;
}
.grid .greyscalelegacy-204-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 1398px;
  width: 107px;
}
.grid .greyscalelegacy-205-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 1626px;
  width: 107px;
}
.grid .greyscalelegacy-205-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 1512px;
  width: 107px;
}
.grid .greyscalelegacy-206-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 1626px;
  width: 106px;
}
.grid .greyscalelegacy-206-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 1512px;
  width: 106px;
}
.grid .greyscalelegacy-207-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 1626px;
  width: 107px;
}
.grid .greyscalelegacy-207-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 1512px;
  width: 107px;
}
.grid .greyscalelegacy-208-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 1618px;
  width: 106px;
}
.grid .greyscalelegacy-208-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 1504px;
  width: 106px;
}
.grid .greyscalelegacy-209-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 1618px;
  width: 107px;
}
.grid .greyscalelegacy-209-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 1504px;
  width: 107px;
}
.grid .greyscalelegacy-210-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 1618px;
  width: 106px;
}
.grid .greyscalelegacy-210-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 1504px;
  width: 106px;
}
.grid .greyscalelegacy-211-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 1618px;
  width: 107px;
}
.grid .greyscalelegacy-211-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 1504px;
  width: 107px;
}
.grid .greyscalelegacy-212-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 1618px;
  width: 107px;
}
.grid .greyscalelegacy-212-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 1504px;
  width: 106px;
}
.grid .greyscalelegacy-213-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 1618px;
  width: 106px;
}
.grid .greyscalelegacy-213-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 1504px;
  width: 106px;
}
.grid .greyscalelegacy-214-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 1618px;
  width: 106px;
}
.grid .greyscalelegacy-214-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 1504px;
  width: 107px;
}
.grid .greyscalelegacy-215-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 1732px;
  width: 107px;
}
.grid .greyscalelegacy-215-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 1619px;
  width: 107px;
}
.grid .greyscalelegacy-220-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 1732px;
  width: 106px;
}
.grid .greyscalelegacy-220-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 1619px;
  width: 106px;
}
.grid .greyscalelegacy-221-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 872px;
  width: 107px;
}
.grid .greyscalelegacy-221-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 759px;
  width: 107px;
}
.grid .greyscalelegacy-233-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 872px;
  width: 106px;
}
.grid .greyscalelegacy-233-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 759px;
  width: 106px;
}
.grid .greyscalelegacy-235-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 872px;
  width: 107px;
}
.grid .greyscalelegacy-235-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 759px;
  width: 107px;
}
.grid .greyscalelegacy-236-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 872px;
  width: 107px;
}
.grid .greyscalelegacy-236-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 759px;
  width: 106px;
}
.grid .greyscalelegacy-237-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 872px;
  width: 106px;
}
.grid .greyscalelegacy-237-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 759px;
  width: 106px;
}
.grid .greyscalelegacy-238-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 872px;
  width: 106px;
}
.grid .greyscalelegacy-238-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 759px;
  width: 107px;
}
.grid .greyscalelegacy-239-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 987px;
  width: 107px;
}
.grid .greyscalelegacy-239-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 873px;
  width: 107px;
}
.grid .greyscalelegacy-240-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 987px;
  width: 106px;
}
.grid .greyscalelegacy-240-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 873px;
  width: 106px;
}
.grid .greyscalelegacy-243-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 987px;
  width: 107px;
}
.grid .greyscalelegacy-243-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 873px;
  width: 107px;
}
.grid .greyscalelegacy-246-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 979px;
  width: 106px;
}
.grid .greyscalelegacy-246-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 865px;
  width: 106px;
}
.grid .greyscalelegacy-248-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 979px;
  width: 107px;
}
.grid .greyscalelegacy-248-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 865px;
  width: 107px;
}
.grid .greyscalelegacy-249-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 979px;
  width: 106px;
}
.grid .greyscalelegacy-249-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 865px;
  width: 106px;
}
.grid .greyscalelegacy-259-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 979px;
  width: 107px;
}
.grid .greyscalelegacy-259-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 865px;
  width: 107px;
}
.grid .greyscalelegacy-264-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 979px;
  width: 107px;
}
.grid .greyscalelegacy-264-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 865px;
  width: 106px;
}
.grid .greyscalelegacy-265-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 979px;
  width: 106px;
}
.grid .greyscalelegacy-265-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 865px;
  width: 106px;
}
.grid .greyscalelegacy-266-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 979px;
  width: 106px;
}
.grid .greyscalelegacy-266-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 865px;
  width: 107px;
}
.grid .greyscalelegacy-267-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 1093px;
  width: 107px;
}
.grid .greyscalelegacy-267-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 980px;
  width: 107px;
}
.grid .greyscalelegacy-268-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 1093px;
  width: 106px;
}
.grid .greyscalelegacy-268-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 980px;
  width: 106px;
}
.grid .greyscalelegacy-269-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 1093px;
  width: 107px;
}
.grid .greyscalelegacy-269-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 980px;
  width: 107px;
}
.grid .greyscalelegacy-270-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 1085px;
  width: 106px;
}
.grid .greyscalelegacy-270-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 972px;
  width: 106px;
}
.grid .greyscalelegacy-271-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 1085px;
  width: 107px;
}
.grid .greyscalelegacy-271-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 972px;
  width: 107px;
}
.grid .greyscalelegacy-272-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 1085px;
  width: 106px;
}
.grid .greyscalelegacy-272-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 972px;
  width: 106px;
}
.grid .greyscalelegacy-273-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 1085px;
  width: 107px;
}
.grid .greyscalelegacy-273-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 972px;
  width: 107px;
}
.grid .greyscalelegacy-274-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 1085px;
  width: 107px;
}
.grid .greyscalelegacy-274-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 972px;
  width: 106px;
}
.grid .greyscalelegacy-275-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 1085px;
  width: 106px;
}
.grid .greyscalelegacy-275-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 972px;
  width: 106px;
}
.grid .greyscalelegacy-276-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 1085px;
  width: 106px;
}
.grid .greyscalelegacy-276-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 972px;
  width: 107px;
}
.grid .greyscalelegacy-277-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 1200px;
  width: 107px;
}
.grid .greyscalelegacy-277-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 1086px;
  width: 107px;
}
.grid .greyscalelegacy-278-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 1200px;
  width: 106px;
}
.grid .greyscalelegacy-278-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 1086px;
  width: 106px;
}
.grid .greyscalelegacy-279-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 1200px;
  width: 107px;
}
.grid .greyscalelegacy-279-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 1086px;
  width: 107px;
}
.grid .greyscalelegacy-280-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 1192px;
  width: 106px;
}
.grid .greyscalelegacy-280-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 1078px;
  width: 106px;
}
.grid .greyscalelegacy-281-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 1192px;
  width: 107px;
}
.grid .greyscalelegacy-281-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 1078px;
  width: 107px;
}
.grid .greyscalelegacy-282-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 1192px;
  width: 106px;
}
.grid .greyscalelegacy-282-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 1078px;
  width: 106px;
}
.grid .greyscalelegacy-283-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 1192px;
  width: 107px;
}
.grid .greyscalelegacy-283-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 1078px;
  width: 107px;
}
.grid .greyscalelegacy-284-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 1192px;
  width: 107px;
}
.grid .greyscalelegacy-284-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 1078px;
  width: 106px;
}
.grid .greyscalelegacy-285-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 1192px;
  width: 106px;
}
.grid .greyscalelegacy-285-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 1078px;
  width: 106px;
}
.grid .greyscalelegacy-286-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 1192px;
  width: 106px;
}
.grid .greyscalelegacy-286-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 1078px;
  width: 107px;
}
.grid .greyscalelegacy-287-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 1307px;
  width: 107px;
}
.grid .greyscalelegacy-287-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 1192px;
  width: 107px;
}
.grid .greyscalelegacy-288-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 1307px;
  width: 106px;
}
.grid .greyscalelegacy-288-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 1192px;
  width: 106px;
}
.grid .greyscalelegacy-289-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 348px;
  width: 107px;
}
.grid .greyscalelegacy-289-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 235px;
  width: 107px;
}
.grid .greyscalelegacy-290-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 340px;
  width: 106px;
}
.grid .greyscalelegacy-290-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 227px;
  width: 106px;
}
.grid .greyscalelegacy-291-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 340px;
  width: 107px;
}
.grid .greyscalelegacy-291-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 227px;
  width: 107px;
}
.grid .greyscalelegacy-292-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 340px;
  width: 106px;
}
.grid .greyscalelegacy-292-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 227px;
  width: 106px;
}
.grid .greyscalelegacy-293-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 340px;
  width: 107px;
}
.grid .greyscalelegacy-293-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 227px;
  width: 107px;
}
.grid .greyscalelegacy-294-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 340px;
  width: 107px;
}
.grid .greyscalelegacy-294-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 227px;
  width: 106px;
}
.grid .greyscalelegacy-295-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 340px;
  width: 106px;
}
.grid .greyscalelegacy-295-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 227px;
  width: 106px;
}
.grid .greyscalelegacy-296-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 340px;
  width: 106px;
}
.grid .greyscalelegacy-296-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 227px;
  width: 107px;
}
.grid .greyscalelegacy-297-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 454px;
  width: 107px;
}
.grid .greyscalelegacy-297-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 341px;
  width: 107px;
}
.grid .greyscalelegacy-298-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 454px;
  width: 106px;
}
.grid .greyscalelegacy-298-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 341px;
  width: 106px;
}
.grid .greyscalelegacy-299-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 454px;
  width: 107px;
}
.grid .greyscalelegacy-299-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 341px;
  width: 107px;
}
.grid .greyscalelegacy-3-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 2150px;
  width: 107px;
}
.grid .greyscalelegacy-3-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 2037px;
  width: 106px;
}
.grid .greyscalelegacy-300-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 446px;
  width: 106px;
}
.grid .greyscalelegacy-300-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 333px;
  width: 106px;
}
.grid .greyscalelegacy-301-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 446px;
  width: 107px;
}
.grid .greyscalelegacy-301-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 333px;
  width: 107px;
}
.grid .greyscalelegacy-302-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 446px;
  width: 106px;
}
.grid .greyscalelegacy-302-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 333px;
  width: 106px;
}
.grid .greyscalelegacy-303-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 446px;
  width: 107px;
}
.grid .greyscalelegacy-303-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 333px;
  width: 107px;
}
.grid .greyscalelegacy-304-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 446px;
  width: 107px;
}
.grid .greyscalelegacy-304-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 333px;
  width: 106px;
}
.grid .greyscalelegacy-305-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 446px;
  width: 106px;
}
.grid .greyscalelegacy-305-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 333px;
  width: 106px;
}
.grid .greyscalelegacy-306-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 446px;
  width: 106px;
}
.grid .greyscalelegacy-306-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 333px;
  width: 107px;
}
.grid .greyscalelegacy-307-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 561px;
  width: 107px;
}
.grid .greyscalelegacy-307-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 448px;
  width: 107px;
}
.grid .greyscalelegacy-308-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 561px;
  width: 106px;
}
.grid .greyscalelegacy-308-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 448px;
  width: 106px;
}
.grid .greyscalelegacy-309-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 561px;
  width: 107px;
}
.grid .greyscalelegacy-309-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 448px;
  width: 107px;
}
.grid .greyscalelegacy-310-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 553px;
  width: 106px;
}
.grid .greyscalelegacy-310-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 440px;
  width: 106px;
}
.grid .greyscalelegacy-311-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 553px;
  width: 107px;
}
.grid .greyscalelegacy-311-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 440px;
  width: 107px;
}
.grid .greyscalelegacy-312-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 553px;
  width: 106px;
}
.grid .greyscalelegacy-312-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 440px;
  width: 106px;
}
.grid .greyscalelegacy-313-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 553px;
  width: 107px;
}
.grid .greyscalelegacy-313-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 440px;
  width: 107px;
}
.grid .greyscalelegacy-314-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 553px;
  width: 107px;
}
.grid .greyscalelegacy-314-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 440px;
  width: 106px;
}
.grid .greyscalelegacy-315-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 553px;
  width: 106px;
}
.grid .greyscalelegacy-315-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 440px;
  width: 106px;
}
.grid .greyscalelegacy-316-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 553px;
  width: 106px;
}
.grid .greyscalelegacy-316-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 440px;
  width: 107px;
}
.grid .greyscalelegacy-317-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 667px;
  width: 107px;
}
.grid .greyscalelegacy-317-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 554px;
  width: 107px;
}
.grid .greyscalelegacy-318-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 667px;
  width: 106px;
}
.grid .greyscalelegacy-318-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 554px;
  width: 106px;
}
.grid .greyscalelegacy-319-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 667px;
  width: 107px;
}
.grid .greyscalelegacy-319-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 554px;
  width: 107px;
}
.grid .greyscalelegacy-320-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 659px;
  width: 106px;
}
.grid .greyscalelegacy-320-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 546px;
  width: 106px;
}
.grid .greyscalelegacy-321-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 659px;
  width: 107px;
}
.grid .greyscalelegacy-321-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 546px;
  width: 107px;
}
.grid .greyscalelegacy-322-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 659px;
  width: 106px;
}
.grid .greyscalelegacy-322-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 546px;
  width: 106px;
}
.grid .greyscalelegacy-323-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 659px;
  width: 107px;
}
.grid .greyscalelegacy-323-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 546px;
  width: 107px;
}
.grid .greyscalelegacy-324-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 659px;
  width: 107px;
}
.grid .greyscalelegacy-324-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 546px;
  width: 106px;
}
.grid .greyscalelegacy-325-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 659px;
  width: 106px;
}
.grid .greyscalelegacy-325-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 546px;
  width: 106px;
}
.grid .greyscalelegacy-326-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 659px;
  width: 106px;
}
.grid .greyscalelegacy-326-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 546px;
  width: 107px;
}
.grid .greyscalelegacy-327-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 774px;
  width: 107px;
}
.grid .greyscalelegacy-327-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 660px;
  width: 107px;
}
.grid .greyscalelegacy-328-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 774px;
  width: 106px;
}
.grid .greyscalelegacy-328-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 660px;
  width: 106px;
}
.grid .greyscalelegacy-329-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 774px;
  width: 107px;
}
.grid .greyscalelegacy-329-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 660px;
  width: 107px;
}
.grid .greyscalelegacy-33-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 2150px;
  width: 106px;
}
.grid .greyscalelegacy-33-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 2037px;
  width: 106px;
}
.grid .greyscalelegacy-330-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 766px;
  width: 106px;
}
.grid .greyscalelegacy-330-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 652px;
  width: 106px;
}
.grid .greyscalelegacy-331-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 766px;
  width: 107px;
}
.grid .greyscalelegacy-331-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 652px;
  width: 107px;
}
.grid .greyscalelegacy-332-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 766px;
  width: 106px;
}
.grid .greyscalelegacy-332-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 652px;
  width: 106px;
}
.grid .greyscalelegacy-333-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 766px;
  width: 107px;
}
.grid .greyscalelegacy-333-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 652px;
  width: 107px;
}
.grid .greyscalelegacy-334-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 766px;
  width: 107px;
}
.grid .greyscalelegacy-334-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 652px;
  width: 106px;
}
.grid .greyscalelegacy-335-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 766px;
  width: 106px;
}
.grid .greyscalelegacy-335-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 652px;
  width: 106px;
}
.grid .greyscalelegacy-336-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 766px;
  width: 106px;
}
.grid .greyscalelegacy-336-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 652px;
  width: 107px;
}
.grid .greyscalelegacy-337-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 880px;
  width: 107px;
}
.grid .greyscalelegacy-337-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 767px;
  width: 107px;
}
.grid .greyscalelegacy-338-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 880px;
  width: 106px;
}
.grid .greyscalelegacy-338-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 767px;
  width: 106px;
}
.grid .greyscalelegacy-339-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 880px;
  width: 107px;
}
.grid .greyscalelegacy-339-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 767px;
  width: 107px;
}
.grid .greyscalelegacy-34-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 2037px;
  width: 107px;
}
.grid .greyscalelegacy-340-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 872px;
  width: 106px;
}
.grid .greyscalelegacy-340-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 759px;
  width: 106px;
}
.grid .greyscalelegacy-35-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 329px;
  object-fit: cover;
  position: absolute;
  top: 1877px;
  width: 107px;
}
.grid .greyscalelegacy-35-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 2151px;
  width: 107px;
}
.grid .greyscalelegacy-36-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 2264px;
  width: 106px;
}
.grid .greyscalelegacy-36-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 2151px;
  width: 106px;
}
.grid .greyscalelegacy-37-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 2151px;
  width: 107px;
}
.grid .greyscalelegacy-38-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 2143px;
  width: 106px;
}
.grid .greyscalelegacy-39-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 2143px;
  width: 107px;
}
.grid .greyscalelegacy-394-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 134px;
  width: 107px;
}
.grid .greyscalelegacy-395-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 134px;
  width: 106px;
}
.grid .greyscalelegacy-396-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 134px;
  width: 107px;
}
.grid .greyscalelegacy-397-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 126px;
  width: 106px;
}
.grid .greyscalelegacy-398-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 126px;
  width: 107px;
}
.grid .greyscalelegacy-399-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 126px;
  width: 106px;
}
.grid .greyscalelegacy-40-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 2143px;
  width: 106px;
}
.grid .greyscalelegacy-400-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 126px;
  width: 107px;
}
.grid .greyscalelegacy-401-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 126px;
  width: 107px;
}
.grid .greyscalelegacy-402-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 126px;
  width: 106px;
}
.grid .greyscalelegacy-403-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 126px;
  width: 106px;
}
.grid .greyscalelegacy-404-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 241px;
  width: 107px;
}
.grid .greyscalelegacy-404-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 128px;
  width: 107px;
}
.grid .greyscalelegacy-405-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 241px;
  width: 106px;
}
.grid .greyscalelegacy-405-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 128px;
  width: 106px;
}
.grid .greyscalelegacy-406-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 241px;
  width: 107px;
}
.grid .greyscalelegacy-406-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 128px;
  width: 107px;
}
.grid .greyscalelegacy-407-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 233px;
  width: 106px;
}
.grid .greyscalelegacy-407-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 120px;
  width: 106px;
}
.grid .greyscalelegacy-408-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 233px;
  width: 107px;
}
.grid .greyscalelegacy-408-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 120px;
  width: 107px;
}
.grid .greyscalelegacy-409-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 233px;
  width: 106px;
}
.grid .greyscalelegacy-409-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 120px;
  width: 106px;
}
.grid .greyscalelegacy-410-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 233px;
  width: 107px;
}
.grid .greyscalelegacy-410-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 120px;
  width: 107px;
}
.grid .greyscalelegacy-411-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 233px;
  width: 107px;
}
.grid .greyscalelegacy-411-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 120px;
  width: 106px;
}
.grid .greyscalelegacy-412-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 233px;
  width: 106px;
}
.grid .greyscalelegacy-412-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 120px;
  width: 106px;
}
.grid .greyscalelegacy-413-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 233px;
  width: 106px;
}
.grid .greyscalelegacy-413-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 120px;
  width: 107px;
}
.grid .greyscalelegacy-414-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 348px;
  width: 107px;
}
.grid .greyscalelegacy-414-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 235px;
  width: 107px;
}
.grid .greyscalelegacy-415-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 348px;
  width: 106px;
}
.grid .greyscalelegacy-415-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 235px;
  width: 106px;
}
.grid .greyscalelegacy-437-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 439px;
  object-fit: cover;
  position: absolute;
  top: 2803px;
  width: 107px;
}
.grid .greyscalelegacy-437-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 441px;
  object-fit: cover;
  position: absolute;
  top: 2689px;
  width: 107px;
}
.grid .greyscalelegacy-441-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 2803px;
  width: 107px;
}
.grid .greyscalelegacy-441-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 548px;
  object-fit: cover;
  position: absolute;
  top: 2689px;
  width: 107px;
}
.grid .greyscalelegacy-442-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 653px;
  object-fit: cover;
  position: absolute;
  top: 2803px;
  width: 107px;
}
.grid .greyscalelegacy-442-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 655px;
  object-fit: cover;
  position: absolute;
  top: 2689px;
  width: 107px;
}
.grid .greyscalelegacy-446-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: 2910px;
  width: 107px;
}
.grid .greyscalelegacy-446-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 13px;
  object-fit: cover;
  position: absolute;
  top: 2796px;
  width: 107px;
}
.grid .greyscalelegacy-447-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 2910px;
  width: 107px;
}
.grid .greyscalelegacy-447-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 2796px;
  width: 107px;
}
.grid .greyscalelegacy-448-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 2910px;
  width: 107px;
}
.grid .greyscalelegacy-448-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 2796px;
  width: 107px;
}
.grid .greyscalelegacy-449-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 332px;
  object-fit: cover;
  position: absolute;
  top: 2910px;
  width: 107px;
}
.grid .greyscalelegacy-449-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 334px;
  object-fit: cover;
  position: absolute;
  top: 2796px;
  width: 107px;
}
.grid .greyscalelegacy-450-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 439px;
  object-fit: cover;
  position: absolute;
  top: 2910px;
  width: 107px;
}
.grid .greyscalelegacy-450-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 441px;
  object-fit: cover;
  position: absolute;
  top: 2796px;
  width: 107px;
}
.grid .greyscalelegacy-451-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 2910px;
  width: 107px;
}
.grid .greyscalelegacy-451-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 548px;
  object-fit: cover;
  position: absolute;
  top: 2796px;
  width: 107px;
}
.grid .greyscalelegacy-452-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 653px;
  object-fit: cover;
  position: absolute;
  top: 2910px;
  width: 107px;
}
.grid .greyscalelegacy-452-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 655px;
  object-fit: cover;
  position: absolute;
  top: 2796px;
  width: 107px;
}
.grid .greyscalelegacy-456-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: 3017px;
  width: 107px;
}
.grid .greyscalelegacy-456-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 13px;
  object-fit: cover;
  position: absolute;
  top: 2903px;
  width: 107px;
}
.grid .greyscalelegacy-457-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 3017px;
  width: 107px;
}
.grid .greyscalelegacy-457-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 2903px;
  width: 107px;
}
.grid .greyscalelegacy-458-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 3017px;
  width: 107px;
}
.grid .greyscalelegacy-458-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 2903px;
  width: 107px;
}
.grid .greyscalelegacy-459-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 332px;
  object-fit: cover;
  position: absolute;
  top: 3017px;
  width: 107px;
}
.grid .greyscalelegacy-459-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 334px;
  object-fit: cover;
  position: absolute;
  top: 2903px;
  width: 107px;
}
.grid .greyscalelegacy-460-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 439px;
  object-fit: cover;
  position: absolute;
  top: 3017px;
  width: 107px;
}
.grid .greyscalelegacy-460-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 441px;
  object-fit: cover;
  position: absolute;
  top: 2903px;
  width: 107px;
}
.grid .greyscalelegacy-461-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 3017px;
  width: 107px;
}
.grid .greyscalelegacy-461-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 548px;
  object-fit: cover;
  position: absolute;
  top: 2903px;
  width: 107px;
}
.grid .greyscalelegacy-462-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 653px;
  object-fit: cover;
  position: absolute;
  top: 3017px;
  width: 107px;
}
.grid .greyscalelegacy-462-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 655px;
  object-fit: cover;
  position: absolute;
  top: 2903px;
  width: 107px;
}
.grid .greyscalelegacy-476-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 13px;
  object-fit: cover;
  position: absolute;
  top: 3010px;
  width: 107px;
}
.grid .greyscalelegacy-479-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 3010px;
  width: 107px;
}
.grid .greyscalelegacy-484-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 3010px;
  width: 107px;
}
.grid .greyscalelegacy-491-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 334px;
  object-fit: cover;
  position: absolute;
  top: 3010px;
  width: 107px;
}
.grid .greyscalelegacy-492-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 441px;
  object-fit: cover;
  position: absolute;
  top: 3010px;
  width: 107px;
}
.grid .greyscalelegacy-493-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 548px;
  object-fit: cover;
  position: absolute;
  top: 3010px;
  width: 107px;
}
.grid .greyscalelegacy-494-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 655px;
  object-fit: cover;
  position: absolute;
  top: 3010px;
  width: 107px;
}
.grid .greyscalelegacy-519-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: 2268px;
  width: 107px;
}
.grid .greyscalelegacy-52-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 2143px;
  width: 107px;
}
.grid .greyscalelegacy-520-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 2268px;
  width: 107px;
}
.grid .greyscalelegacy-521-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 2268px;
  width: 107px;
}
.grid .greyscalelegacy-522-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 332px;
  object-fit: cover;
  position: absolute;
  top: 2268px;
  width: 107px;
}
.grid .greyscalelegacy-523-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 439px;
  object-fit: cover;
  position: absolute;
  top: 2268px;
  width: 107px;
}
.grid .greyscalelegacy-524-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 2268px;
  width: 107px;
}
.grid .greyscalelegacy-525-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 653px;
  object-fit: cover;
  position: absolute;
  top: 2268px;
  width: 107px;
}
.grid .greyscalelegacy-529-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: 2375px;
  width: 107px;
}
.grid .greyscalelegacy-530-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 2375px;
  width: 107px;
}
.grid .greyscalelegacy-531-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 2375px;
  width: 107px;
}
.grid .greyscalelegacy-532-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 332px;
  object-fit: cover;
  position: absolute;
  top: 2375px;
  width: 107px;
}
.grid .greyscalelegacy-533-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 439px;
  object-fit: cover;
  position: absolute;
  top: 2375px;
  width: 107px;
}
.grid .greyscalelegacy-534-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 2375px;
  width: 107px;
}
.grid .greyscalelegacy-535-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 653px;
  object-fit: cover;
  position: absolute;
  top: 2375px;
  width: 107px;
}
.grid .greyscalelegacy-539-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: 2482px;
  width: 107px;
}
.grid .greyscalelegacy-539-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 13px;
  object-fit: cover;
  position: absolute;
  top: 2368px;
  width: 107px;
}
.grid .greyscalelegacy-540-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 2482px;
  width: 107px;
}
.grid .greyscalelegacy-540-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 2368px;
  width: 107px;
}
.grid .greyscalelegacy-541-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 2482px;
  width: 107px;
}
.grid .greyscalelegacy-541-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 2368px;
  width: 107px;
}
.grid .greyscalelegacy-542-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 332px;
  object-fit: cover;
  position: absolute;
  top: 2482px;
  width: 107px;
}
.grid .greyscalelegacy-542-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 334px;
  object-fit: cover;
  position: absolute;
  top: 2368px;
  width: 107px;
}
.grid .greyscalelegacy-543-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 439px;
  object-fit: cover;
  position: absolute;
  top: 2482px;
  width: 107px;
}
.grid .greyscalelegacy-543-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 441px;
  object-fit: cover;
  position: absolute;
  top: 2368px;
  width: 107px;
}
.grid .greyscalelegacy-544-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 2482px;
  width: 107px;
}
.grid .greyscalelegacy-544-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 548px;
  object-fit: cover;
  position: absolute;
  top: 2368px;
  width: 107px;
}
.grid .greyscalelegacy-545-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 653px;
  object-fit: cover;
  position: absolute;
  top: 2482px;
  width: 107px;
}
.grid .greyscalelegacy-545-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 655px;
  object-fit: cover;
  position: absolute;
  top: 2368px;
  width: 107px;
}
.grid .greyscalelegacy-549-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: 2589px;
  width: 107px;
}
.grid .greyscalelegacy-549-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 13px;
  object-fit: cover;
  position: absolute;
  top: 2475px;
  width: 107px;
}
.grid .greyscalelegacy-550-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 2589px;
  width: 107px;
}
.grid .greyscalelegacy-550-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 2475px;
  width: 107px;
}
.grid .greyscalelegacy-551-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 2589px;
  width: 107px;
}
.grid .greyscalelegacy-551-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 2475px;
  width: 107px;
}
.grid .greyscalelegacy-552-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 332px;
  object-fit: cover;
  position: absolute;
  top: 2589px;
  width: 107px;
}
.grid .greyscalelegacy-552-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 334px;
  object-fit: cover;
  position: absolute;
  top: 2475px;
  width: 107px;
}
.grid .greyscalelegacy-553-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 439px;
  object-fit: cover;
  position: absolute;
  top: 2589px;
  width: 107px;
}
.grid .greyscalelegacy-553-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 441px;
  object-fit: cover;
  position: absolute;
  top: 2475px;
  width: 107px;
}
.grid .greyscalelegacy-554-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 2589px;
  width: 107px;
}
.grid .greyscalelegacy-554-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 548px;
  object-fit: cover;
  position: absolute;
  top: 2475px;
  width: 107px;
}
.grid .greyscalelegacy-555-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 653px;
  object-fit: cover;
  position: absolute;
  top: 2589px;
  width: 107px;
}
.grid .greyscalelegacy-555-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 655px;
  object-fit: cover;
  position: absolute;
  top: 2475px;
  width: 107px;
}
.grid .greyscalelegacy-559-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: 2696px;
  width: 107px;
}
.grid .greyscalelegacy-559-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 13px;
  object-fit: cover;
  position: absolute;
  top: 2582px;
  width: 107px;
}
.grid .greyscalelegacy-560-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 2696px;
  width: 107px;
}
.grid .greyscalelegacy-560-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 2582px;
  width: 107px;
}
.grid .greyscalelegacy-561-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 2696px;
  width: 107px;
}
.grid .greyscalelegacy-561-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 2582px;
  width: 107px;
}
.grid .greyscalelegacy-562-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 332px;
  object-fit: cover;
  position: absolute;
  top: 2696px;
  width: 107px;
}
.grid .greyscalelegacy-562-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 334px;
  object-fit: cover;
  position: absolute;
  top: 2582px;
  width: 107px;
}
.grid .greyscalelegacy-563-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 439px;
  object-fit: cover;
  position: absolute;
  top: 2696px;
  width: 107px;
}
.grid .greyscalelegacy-563-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 441px;
  object-fit: cover;
  position: absolute;
  top: 2582px;
  width: 107px;
}
.grid .greyscalelegacy-564-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 2696px;
  width: 107px;
}
.grid .greyscalelegacy-564-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 548px;
  object-fit: cover;
  position: absolute;
  top: 2582px;
  width: 107px;
}
.grid .greyscalelegacy-565-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 653px;
  object-fit: cover;
  position: absolute;
  top: 2696px;
  width: 107px;
}
.grid .greyscalelegacy-565-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 655px;
  object-fit: cover;
  position: absolute;
  top: 2582px;
  width: 107px;
}
.grid .greyscalelegacy-569-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: 2803px;
  width: 107px;
}
.grid .greyscalelegacy-569-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 13px;
  object-fit: cover;
  position: absolute;
  top: 2689px;
  width: 107px;
}
.grid .greyscalelegacy-570-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 2803px;
  width: 107px;
}
.grid .greyscalelegacy-570-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 2689px;
  width: 107px;
}
.grid .greyscalelegacy-571-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 2803px;
  width: 107px;
}
.grid .greyscalelegacy-571-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 2689px;
  width: 107px;
}
.grid .greyscalelegacy-572-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 332px;
  object-fit: cover;
  position: absolute;
  top: 2803px;
  width: 107px;
}
.grid .greyscalelegacy-572-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 334px;
  object-fit: cover;
  position: absolute;
  top: 2689px;
  width: 107px;
}
.grid .greyscalelegacy-83-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 1945px;
  width: 107px;
}
.grid .greyscalelegacy-83-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 1832px;
  width: 107px;
}
.grid .greyscalelegacy-86-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 1945px;
  width: 106px;
}
.grid .greyscalelegacy-86-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 1832px;
  width: 106px;
}
.grid .greyscalelegacy-95-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 1945px;
  width: 107px;
}
.grid .greyscalelegacy-95-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 1832px;
  width: 107px;
}
.grid .greyscalelegacy-96-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 1937px;
  width: 106px;
}
.grid .greyscalelegacy-96-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 1824px;
  width: 106px;
}
.grid .greyscalelegacy-97-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 1937px;
  width: 107px;
}
.grid .greyscalelegacy-97-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 1824px;
  width: 107px;
}
.grid .greyscalelegacy-98-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 1937px;
  width: 106px;
}
.grid .greyscalelegacy-98-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 1824px;
  width: 106px;
}
.grid .greyscalelegacy-99-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 1937px;
  width: 107px;
}
.grid .greyscalelegacy-99-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 1824px;
  width: 107px;
}
.grid .grotto-labs-logo-3-C61RwL {
  background-color: transparent;
  height: 78px;
  left: 93px;
  object-fit: cover;
  position: absolute;
  top: 3219px;
  width: 67px;
}
.grid .xadt00000099j48-balegacy212-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 2143px;
  width: 106px;
}
.grid .xadt0000009bj48-balegacy972-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 2143px;
  width: 106px;
}

/* screen - home */
.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  -webkit-transition: opacity .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 23px;
  height: 24px;
  border: 0;
  background: black;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 23px;
  height: 24px;
  border: 0;
  background: green;
  cursor: pointer;
}

.num-purchase-jabbas {
  text-align: center
}

/* screen - purchase */

.purchase {
  align-items: flex-end;
  background-color: var(--white);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 2100px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  padding: 33px 35.3px;
  position: relative;
  width: 789px;
}
.purchase .asset-18-prov-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 15px;
  margin-bottom: 1.0px;
  margin-left: 42px;
  object-fit: cover;
  position: relative;
  width: 68px;
}
.purchase .asset-20-gallery-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 15px;
  margin-bottom: 1.0px;
  margin-left: 31px;
  object-fit: cover;
  position: relative;
  width: 85px;
}
.purchase .asset-21-purchase-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 17px;
  margin-bottom: 1.0px;
  margin-left: 49px;
  object-fit: cover;
  position: relative;
  width: 83px;
}
.purchase .asset-22-purchjabb-2-NG5cxJ {
  background-color: transparent;
  height: 88px;
  left: 230px;
  object-fit: cover;
  position: absolute;
  top: 741px;
  width: 255px;
  cursor: pointer;
  z-index:2;
}
.purchase .asset-23-wallet-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 15px;
  margin-bottom: 1.0px;
  margin-left: 29px;
  object-fit: cover;
  position: relative;
  width: 99px;
}
.purchase .asset-26-j48-messy-1-H5vPvf {
  background-color: transparent;
  height: 120px;
  left: 196px;
  object-fit: cover;
  position: absolute;
  top: 31px;
  width: 190px;
}
.purchase .asset-28-bounty-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 20px;
  margin-left: 30px;
  object-fit: cover;
  position: relative;
  width: 88px;
}
.purchase .asset-3-l1-2-H5vPvf {
  background-color: transparent;
  height: 55px;
  left: 36px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 488px;
}
.purchase .asset-31-main-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 15px;
  margin-bottom: 1.0px;
  object-fit: cover;
  position: relative;
  width: 51px;
}
.purchase .asset-34-twitter-1-fKmFVR {
  background-color: transparent;
  flex-shrink: 1;
  height: 28px;
  margin-left: 14px;
  margin-top: 35.52px;
  object-fit: cover;
  position: relative;
  width: 33px;
}
.purchase .asset-35-discord-1-ASQbkE {
  background-color: transparent;
  flex-shrink: 1;
  height: 22px;
  object-fit: cover;
  position: relative;
  width: 40px;
}
.purchase .asset-4-l3-2-52Wjwg {
  background-color: transparent;
  height: 47px;
  left: 3px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 613px;
}
.purchase .asset-41-grottolab1-1-H5vPvf {
  background-color: transparent;
  height: 48px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 60px;
  width: 58px;
}
.purchase .asset-67curve-2-xpmbvu {
  background-color: transparent;
  flex-shrink: 1;
  height: 310px;
  object-fit: cover;
  position: relative;
  width: 640px;
}
.purchase .bwgan-221-GyJUNH {
  background-color: transparent;
  flex-shrink: 1;
  height: 182px;
  margin-left: 118px;
  object-fit: cover;
  position: relative;
  width: 134px;
}
.purchase .bwgan-226-GyJUNH {
  align-self: center;
  background-color: transparent;
  flex-shrink: 1;
  height: 168px;
  margin-bottom: 8.0px;
  margin-left: 113px;
  object-fit: cover;
  position: relative;
  width: 149px;
}
.purchase .bwgan-251-GyJUNH {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: 181px;
  object-fit: cover;
  position: relative;
  width: 135px;
}
.purchase .clickthecan-1-0xy0vn {
  background-color: transparent;
  flex-shrink: 1;
  height: 36px;
  margin-left: 19.26px;
  margin-top: 25px;
  object-fit: cover;
  position: relative;
  width: 377px;
}
.purchase .currentpricejabbaform-1-52Wjwg {
  background-color: transparent;
  height: 151px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 34px;
  width: 309px;
}
.purchase .flex-col-0xy0vn {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-right: 29.74px;
  margin-top: 35px;
  min-height: 490px;
  position: relative;
  width: 640px;
}
.purchase .flex-col-C61RwL {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 28px;
  min-height: 1849px;
  position: relative;
  width: 710px;
}
.purchase .flex-col-H5vPvf {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 498px;
  min-height: 56px;
  position: absolute;
  top: 83px;
  width: 40px;
}
.purchase .flex-col-IMpwyX {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 637px;
  min-height: 194px;
  position: absolute;
  top: 672px;
  width: 72px;
}
.purchase .flex-col-NG5cxJ {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 0px;
  min-height: 179px;
  position: absolute;
  top: 676px;
  width: 73px;
}
.purchase .flex-row-0xy0vn {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 186px;
  justify-content: flex-start;
  margin-right: 10.74px;
  margin-top: 14px;
  min-width: 649px;
  position: relative;
  width: auto;
}
.purchase .flex-row-C61RwL {
  align-items: center;
  align-self: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 20px;
  justify-content: flex-start;
  margin-left: 3.46px;
  min-width: 657px;
  position: relative;
  width: auto;
}
.purchase .flex-row-xpmbvu {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 150px;
  justify-content: flex-start;
  margin-right: 7px;
  margin-top: 30px;
  min-width: 585px;
  position: relative;
  width: auto;
}
.purchase .frame-1-ASQbkE {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  margin-right: 16.89px;
  margin-top: 33px;
  overflow: hidden;
  position: relative;
  width: 6px;
}
.purchase .frame-3-NG5cxJ {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 124px;
  justify-content: flex-end;
  left: 178px;
  min-width: 358px;
  padding: 6.8px 108.1px;
  position: absolute;
  top: 709px;
  -webkit-transform: rotate(-0.51deg);
          transform: rotate(-0.51deg);
  width: auto;
}
.purchase .frame-4-NG5cxJ {
  background-color: transparent;
  height: 4px;
  left: 374px;
  overflow: hidden;
  position: absolute;
  top: 737px;
  width: 4px;
}
.purchase .frame-5-AdpH7j {
  background-color: transparent;
  flex-shrink: 1;
  height: 100px;
  overflow: hidden;
  position: relative;
  -webkit-transform: rotate(0.50deg);
          transform: rotate(0.50deg);
  width: 100px;
}
.purchase .grotto-labs-logo-5-H5vPvf {
  background-color: transparent;
  height: 78px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 55px;
  width: 67px;
}
.purchase .mintbutton {
  background-color: transparent;
  margin-left: -150px;
  width: 324px;
  top: 300px;
  position: absolute;
}
.purchase .texttt {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0.00px;
  line-height: normal;
  min-height: 398px;
  position: center;
  text-align: center;
  width: 583px;
}
.purchase .overlap-group-fKmFVR {
  background-color: transparent;
  flex-shrink: 1;
  height: 150px;
  position: relative;
  width: 538px;
}
.purchase .overlap-group1-0xy0vn {
  background-color: transparent;
  flex-shrink: 1;
  height: 871px;
  position: relative;
  width: 710px;
  margin-bottom:300px;
}
.purchase .overlap-group2-0xy0vn {
  background-color: transparent;
  flex-shrink: 1;
  height: 185px;
  margin-right: 0.18px;
  margin-top: 6px;
  position: relative;
  width: 616px;
}
.purchase .shiny-1-NG5cxJ {
  background-color: transparent;
  height: 54px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: 586px;
  width: 57px;
}
.purchase .shiny-2-xbkSRC {
  background-color: transparent;
  flex-shrink: 1;
  height: 88px;
  object-fit: cover;
  position: relative;
  width: 73px;
}
.purchase .shiny-3-xbkSRC {
  background-color: transparent;
  flex-shrink: 1;
  height: 54px;
  margin-right: 1.07px;
  margin-top: 36px;
  object-fit: cover;
  position: relative;
  width: 58px;
}
.purchase .shiny-4-NG5cxJ {
  background-color: transparent;
  height: 54px;
  left: 633px;
  object-fit: cover;
  position: absolute;
  top: 586px;
  width: 57px;
}
.purchase .shiny-5-y0Ojnz {
  background-color: transparent;
  flex-shrink: 1;
  height: 64px;
  margin-left: 3.89px;
  margin-top: 45px;
  object-fit: cover;
  position: relative;
  width: 66px;
}
.purchase .shiny-6-y0Ojnz {
  background-color: transparent;
  flex-shrink: 1;
  height: 86px;
  object-fit: cover;
  position: relative;
  width: 72px;
}
.purchase .x08-eth-1-52Wjwg {
  background-color: transparent;
  height: 93px;
  left: 346px;
  object-fit: cover;
  position: absolute;
  top: 74px;
  width: 244px;
}

.purchase .connect-wallet-52Wjwg {
  height: 93px;
  left: 346px;
  object-fit: cover;
  position: absolute;
  top: 74px;
  width: 244px;
}

.purchase .trinkhead {
  background-color: transparent;
  height: 590px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 100%;
}

