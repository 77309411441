/* screen - grid */

.container-center-horizontal{
  margin-top:0px;
}

.blocker{
  position:fixed;
  top:0px;
  left:0px;
  height:60px;
  width:100%;
  background:white;
  z-index:1;
}

.slider {
  animation: marquee 60s linear infinite;
}

@keyframes marquee {
  from {
    transform: translateY(0);
  }
  to { 
    transform: translateY(0);
  }
}

.grid {
  background-color: var(--white);
  height: 3500px;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 780px;
  margin-top:-50px;
  display:block;
}

.grid .asset-18-prov-2-C61RwL {
  background-color: transparent;
  cursor: pointer;
  height: 15px;
  left: 649px;
  object-fit: cover;
  position: absolute;
  top: 35px;
  width: 68px;
  animation-direction: alternate;
  animation: pulse 3s infinite;

}
.grid .asset-20-gallery-2-C61RwL {
  background-color: transparent;
  cursor: pointer;
  height: 15px;
  left: 522px;
  object-fit: cover;
  position: absolute;
  top: 37px;
  width: 85px;
}
.grid .asset-21-purchase-2-C61RwL {
  background-color: transparent;
  cursor: pointer;
  height: 17px;
  left: 160px;
  object-fit: cover;
  position: absolute;
  top: 35px;
  width: 83px;
}
.grid .asset-23-wallet-3-C61RwL {
  background-color: transparent;
  cursor: pointer;
  height: 15px;
  left: 273px;
  object-fit: cover;
  position: absolute;
  top: 36px;
  width: 99px;
}
.grid .asset-26-j48-messy-1-C61RwL {
  background-color: transparent;
  height: 120px;
  left: 294px;
  object-fit: cover;
  position: absolute;
  top: 3198px;
  width: 190px;
}
.grid .asset-28-bounty-2-C61RwL {
  background-color: transparent;
  height: 20px;
  left: 402px;
  object-fit: cover;
  position: absolute;
  top: 35px;
  width: 88px;
}
.grid .asset-3-l1-2-C61RwL {
  background-color: transparent;
  height: 55px;
  left: 134px;
  object-fit: cover;
  position: absolute;
  top: 3167px;
  width: 488px;
}
.grid .asset-31-main-2-C61RwL {
  background-color: transparent;
  cursor: pointer;
  height: 15px;
  left: 60px;
  object-fit: cover;
  position: absolute;
  top: 35px;
  width: 51px;
}
.grid .asset-34-twitter-1-C61RwL {
  background-color: transparent;
  height: 28px;
  left: 650px;
  object-fit: cover;
  position: absolute;
  top: 3246px;
  width: 33px;
}
.grid .asset-35-discord-1-C61RwL {
  background-color: transparent;
  height: 22px;
  left: 596px;
  object-fit: cover;
  position: absolute;
  top: 3250px;
  width: 40px;
}
.grid .asset-41-grottolab1-1-C61RwL {
  background-color: transparent;
  height: 48px;
  left: 98px;
  object-fit: cover;
  position: absolute;
  top: 3227px;
  width: 58px;
}
.grid .coloredgans1-150-C61RwL {
  background-color: transparent;
  height: 111px;
  left: 1052px;
  object-fit: cover;
  position: absolute;
  top: 2843px;
  width: 111px;
}
.grid .coloredgans1-152-C61RwL {
  background-color: transparent;
  height: 111px;
  left: 944px;
  object-fit: cover;
  position: absolute;
  top: 2838px;
  width: 111px;
}
.grid .coloredgans1-154-C61RwL {
  background-color: transparent;
  height: 111px;
  left: 830px;
  object-fit: cover;
  position: absolute;
  top: 2838px;
  width: 111px;
}
.grid .greyscalelegacy-1-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 2150px;
  width: 106px;
}
.grid .greyscalelegacy-1-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 2037px;
  width: 106px;
}
.grid .greyscalelegacy-100-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 1937px;
  width: 107px;
}
.grid .greyscalelegacy-100-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 1824px;
  width: 106px;
}
.grid .greyscalelegacy-101-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 1937px;
  width: 106px;
}
.grid .greyscalelegacy-101-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 1824px;
  width: 106px;
}
.grid .greyscalelegacy-102-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 1937px;
  width: 106px;
}
.grid .greyscalelegacy-102-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 1824px;
  width: 107px;
}
.grid .greyscalelegacy-103-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 2052px;
  width: 107px;
}
.grid .greyscalelegacy-103-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 1938px;
  width: 107px;
}
.grid .greyscalelegacy-104-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 2052px;
  width: 106px;
}
.grid .greyscalelegacy-104-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 1938px;
  width: 106px;
}
.grid .greyscalelegacy-105-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 2052px;
  width: 107px;
}
.grid .greyscalelegacy-105-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 1938px;
  width: 107px;
}
.grid .greyscalelegacy-106-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 2044px;
  width: 106px;
}
.grid .greyscalelegacy-106-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 1930px;
  width: 106px;
}
.grid .greyscalelegacy-107-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 2044px;
  width: 107px;
}
.grid .greyscalelegacy-107-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 1930px;
  width: 107px;
}
.grid .greyscalelegacy-108-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 2044px;
  width: 106px;
}
.grid .greyscalelegacy-108-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 1930px;
  width: 106px;
}
.grid .greyscalelegacy-109-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 2044px;
  width: 107px;
}
.grid .greyscalelegacy-109-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 1930px;
  width: 107px;
}
.grid .greyscalelegacy-110-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 2044px;
  width: 107px;
}
.grid .greyscalelegacy-110-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 1930px;
  width: 106px;
}
.grid .greyscalelegacy-111-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 2044px;
  width: 106px;
}
.grid .greyscalelegacy-111-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 1930px;
  width: 106px;
}
.grid .greyscalelegacy-112-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 2044px;
  width: 106px;
}
.grid .greyscalelegacy-112-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 1930px;
  width: 107px;
}
.grid .greyscalelegacy-113-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 2158px;
  width: 107px;
}
.grid .greyscalelegacy-113-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 2045px;
  width: 107px;
}
.grid .greyscalelegacy-114-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 2158px;
  width: 106px;
}
.grid .greyscalelegacy-114-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 2045px;
  width: 106px;
}
.grid .greyscalelegacy-115-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 2158px;
  width: 107px;
}
.grid .greyscalelegacy-115-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 2045px;
  width: 107px;
}
.grid .greyscalelegacy-119-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 2150px;
  width: 106px;
}
.grid .greyscalelegacy-119-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 2037px;
  width: 106px;
}
.grid .greyscalelegacy-123-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 2150px;
  width: 107px;
}
.grid .greyscalelegacy-123-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 2037px;
  width: 107px;
}
.grid .greyscalelegacy-132-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 1732px;
  width: 107px;
}
.grid .greyscalelegacy-132-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 1619px;
  width: 107px;
}
.grid .greyscalelegacy-133-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 1724px;
  width: 106px;
}
.grid .greyscalelegacy-133-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 1611px;
  width: 106px;
}
.grid .greyscalelegacy-135-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 1724px;
  width: 107px;
}
.grid .greyscalelegacy-135-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 1611px;
  width: 107px;
}
.grid .greyscalelegacy-139-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 1724px;
  width: 106px;
}
.grid .greyscalelegacy-139-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 1611px;
  width: 106px;
}
.grid .greyscalelegacy-140-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 1724px;
  width: 107px;
}
.grid .greyscalelegacy-140-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 1611px;
  width: 107px;
}
.grid .greyscalelegacy-141-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 1724px;
  width: 107px;
}
.grid .greyscalelegacy-141-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 1611px;
  width: 106px;
}
.grid .greyscalelegacy-142-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 1724px;
  width: 106px;
}
.grid .greyscalelegacy-142-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 1611px;
  width: 106px;
}
.grid .greyscalelegacy-143-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 1724px;
  width: 106px;
}
.grid .greyscalelegacy-143-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 1611px;
  width: 107px;
}
.grid .greyscalelegacy-144-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 1839px;
  width: 107px;
}
.grid .greyscalelegacy-144-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 1725px;
  width: 107px;
}
.grid .greyscalelegacy-145-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 1839px;
  width: 106px;
}
.grid .greyscalelegacy-145-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 1725px;
  width: 106px;
}
.grid .greyscalelegacy-146-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 1839px;
  width: 107px;
}
.grid .greyscalelegacy-146-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 1725px;
  width: 107px;
}
.grid .greyscalelegacy-147-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 1831px;
  width: 106px;
}
.grid .greyscalelegacy-147-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 1717px;
  width: 106px;
}
.grid .greyscalelegacy-148-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 1831px;
  width: 107px;
}
.grid .greyscalelegacy-148-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 1717px;
  width: 107px;
}
.grid .greyscalelegacy-149-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 1831px;
  width: 106px;
}
.grid .greyscalelegacy-149-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 1717px;
  width: 106px;
}
.grid .greyscalelegacy-150-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 1831px;
  width: 107px;
}
.grid .greyscalelegacy-150-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 1717px;
  width: 107px;
}
.grid .greyscalelegacy-151-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 1831px;
  width: 107px;
}
.grid .greyscalelegacy-151-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 1717px;
  width: 106px;
}
.grid .greyscalelegacy-152-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 1831px;
  width: 106px;
}
.grid .greyscalelegacy-152-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 1717px;
  width: 106px;
}
.grid .greyscalelegacy-153-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 1831px;
  width: 106px;
}
.grid .greyscalelegacy-153-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 1717px;
  width: 107px;
}
.grid .greyscalelegacy-154-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 1307px;
  width: 107px;
}
.grid .greyscalelegacy-154-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 1192px;
  width: 107px;
}
.grid .greyscalelegacy-155-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 1299px;
  width: 106px;
}
.grid .greyscalelegacy-155-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 1184px;
  width: 106px;
}
.grid .greyscalelegacy-156-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 1299px;
  width: 107px;
}
.grid .greyscalelegacy-156-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 1184px;
  width: 107px;
}
.grid .greyscalelegacy-157-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 1299px;
  width: 106px;
}
.grid .greyscalelegacy-157-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 1184px;
  width: 106px;
}
.grid .greyscalelegacy-158-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 1299px;
  width: 107px;
}
.grid .greyscalelegacy-158-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 1184px;
  width: 107px;
}
.grid .greyscalelegacy-159-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 1299px;
  width: 107px;
}
.grid .greyscalelegacy-159-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 1184px;
  width: 106px;
}
.grid .greyscalelegacy-160-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 1299px;
  width: 106px;
}
.grid .greyscalelegacy-160-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 1184px;
  width: 106px;
}
.grid .greyscalelegacy-161-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 1299px;
  width: 106px;
}
.grid .greyscalelegacy-161-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 1184px;
  width: 107px;
}
.grid .greyscalelegacy-162-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 1413px;
  width: 107px;
}
.grid .greyscalelegacy-162-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 1299px;
  width: 107px;
}
.grid .greyscalelegacy-163-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 1413px;
  width: 106px;
}
.grid .greyscalelegacy-163-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 1299px;
  width: 106px;
}
.grid .greyscalelegacy-164-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 1413px;
  width: 107px;
}
.grid .greyscalelegacy-164-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 1299px;
  width: 107px;
}
.grid .greyscalelegacy-165-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 1405px;
  width: 106px;
}
.grid .greyscalelegacy-165-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 1291px;
  width: 106px;
}
.grid .greyscalelegacy-173-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 1405px;
  width: 107px;
}
.grid .greyscalelegacy-173-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 1291px;
  width: 107px;
}
.grid .greyscalelegacy-179-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 1405px;
  width: 106px;
}
.grid .greyscalelegacy-179-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 1291px;
  width: 106px;
}
.grid .greyscalelegacy-181-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 1405px;
  width: 107px;
}
.grid .greyscalelegacy-181-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 1291px;
  width: 107px;
}
.grid .greyscalelegacy-183-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 1405px;
  width: 107px;
}
.grid .greyscalelegacy-183-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 1291px;
  width: 106px;
}
.grid .greyscalelegacy-184-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 1405px;
  width: 106px;
}
.grid .greyscalelegacy-184-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 1291px;
  width: 106px;
}
.grid .greyscalelegacy-188-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 1405px;
  width: 106px;
}
.grid .greyscalelegacy-188-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 1291px;
  width: 107px;
}
.grid .greyscalelegacy-190-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 1519px;
  width: 107px;
}
.grid .greyscalelegacy-190-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 1406px;
  width: 107px;
}
.grid .greyscalelegacy-193-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 1519px;
  width: 106px;
}
.grid .greyscalelegacy-193-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 1406px;
  width: 106px;
}
.grid .greyscalelegacy-195-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 1519px;
  width: 107px;
}
.grid .greyscalelegacy-195-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 1406px;
  width: 107px;
}
.grid .greyscalelegacy-198-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 1511px;
  width: 106px;
}
.grid .greyscalelegacy-198-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 1398px;
  width: 106px;
}
.grid .greyscalelegacy-199-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 1511px;
  width: 107px;
}
.grid .greyscalelegacy-199-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 1398px;
  width: 107px;
}
.grid .greyscalelegacy-2-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 2150px;
  width: 107px;
}
.grid .greyscalelegacy-2-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 2037px;
  width: 107px;
}
.grid .greyscalelegacy-200-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 1511px;
  width: 106px;
}
.grid .greyscalelegacy-200-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 1398px;
  width: 106px;
}
.grid .greyscalelegacy-201-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 1511px;
  width: 107px;
}
.grid .greyscalelegacy-201-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 1398px;
  width: 107px;
}
.grid .greyscalelegacy-202-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 1511px;
  width: 107px;
}
.grid .greyscalelegacy-202-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 1398px;
  width: 106px;
}
.grid .greyscalelegacy-203-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 1511px;
  width: 106px;
}
.grid .greyscalelegacy-203-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 1398px;
  width: 106px;
}
.grid .greyscalelegacy-204-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 1511px;
  width: 106px;
}
.grid .greyscalelegacy-204-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 1398px;
  width: 107px;
}
.grid .greyscalelegacy-205-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 1626px;
  width: 107px;
}
.grid .greyscalelegacy-205-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 1512px;
  width: 107px;
}
.grid .greyscalelegacy-206-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 1626px;
  width: 106px;
}
.grid .greyscalelegacy-206-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 1512px;
  width: 106px;
}
.grid .greyscalelegacy-207-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 1626px;
  width: 107px;
}
.grid .greyscalelegacy-207-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 1512px;
  width: 107px;
}
.grid .greyscalelegacy-208-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 1618px;
  width: 106px;
}
.grid .greyscalelegacy-208-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 1504px;
  width: 106px;
}
.grid .greyscalelegacy-209-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 1618px;
  width: 107px;
}
.grid .greyscalelegacy-209-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 1504px;
  width: 107px;
}
.grid .greyscalelegacy-210-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 1618px;
  width: 106px;
}
.grid .greyscalelegacy-210-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 1504px;
  width: 106px;
}
.grid .greyscalelegacy-211-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 1618px;
  width: 107px;
}
.grid .greyscalelegacy-211-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 1504px;
  width: 107px;
}
.grid .greyscalelegacy-212-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 1618px;
  width: 107px;
}
.grid .greyscalelegacy-212-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 1504px;
  width: 106px;
}
.grid .greyscalelegacy-213-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 1618px;
  width: 106px;
}
.grid .greyscalelegacy-213-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 1504px;
  width: 106px;
}
.grid .greyscalelegacy-214-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 1618px;
  width: 106px;
}
.grid .greyscalelegacy-214-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 1504px;
  width: 107px;
}
.grid .greyscalelegacy-215-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 1732px;
  width: 107px;
}
.grid .greyscalelegacy-215-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 1619px;
  width: 107px;
}
.grid .greyscalelegacy-220-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 1732px;
  width: 106px;
}
.grid .greyscalelegacy-220-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 1619px;
  width: 106px;
}
.grid .greyscalelegacy-221-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 872px;
  width: 107px;
}
.grid .greyscalelegacy-221-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 759px;
  width: 107px;
}
.grid .greyscalelegacy-233-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 872px;
  width: 106px;
}
.grid .greyscalelegacy-233-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 759px;
  width: 106px;
}
.grid .greyscalelegacy-235-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 872px;
  width: 107px;
}
.grid .greyscalelegacy-235-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 759px;
  width: 107px;
}
.grid .greyscalelegacy-236-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 872px;
  width: 107px;
}
.grid .greyscalelegacy-236-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 759px;
  width: 106px;
}
.grid .greyscalelegacy-237-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 872px;
  width: 106px;
}
.grid .greyscalelegacy-237-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 759px;
  width: 106px;
}
.grid .greyscalelegacy-238-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 872px;
  width: 106px;
}
.grid .greyscalelegacy-238-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 759px;
  width: 107px;
}
.grid .greyscalelegacy-239-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 987px;
  width: 107px;
}
.grid .greyscalelegacy-239-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 873px;
  width: 107px;
}
.grid .greyscalelegacy-240-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 987px;
  width: 106px;
}
.grid .greyscalelegacy-240-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 873px;
  width: 106px;
}
.grid .greyscalelegacy-243-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 987px;
  width: 107px;
}
.grid .greyscalelegacy-243-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 873px;
  width: 107px;
}
.grid .greyscalelegacy-246-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 979px;
  width: 106px;
}
.grid .greyscalelegacy-246-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 865px;
  width: 106px;
}
.grid .greyscalelegacy-248-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 979px;
  width: 107px;
}
.grid .greyscalelegacy-248-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 865px;
  width: 107px;
}
.grid .greyscalelegacy-249-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 979px;
  width: 106px;
}
.grid .greyscalelegacy-249-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 865px;
  width: 106px;
}
.grid .greyscalelegacy-259-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 979px;
  width: 107px;
}
.grid .greyscalelegacy-259-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 865px;
  width: 107px;
}
.grid .greyscalelegacy-264-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 979px;
  width: 107px;
}
.grid .greyscalelegacy-264-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 865px;
  width: 106px;
}
.grid .greyscalelegacy-265-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 979px;
  width: 106px;
}
.grid .greyscalelegacy-265-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 865px;
  width: 106px;
}
.grid .greyscalelegacy-266-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 979px;
  width: 106px;
}
.grid .greyscalelegacy-266-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 865px;
  width: 107px;
}
.grid .greyscalelegacy-267-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 1093px;
  width: 107px;
}
.grid .greyscalelegacy-267-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 980px;
  width: 107px;
}
.grid .greyscalelegacy-268-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 1093px;
  width: 106px;
}
.grid .greyscalelegacy-268-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 980px;
  width: 106px;
}
.grid .greyscalelegacy-269-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 1093px;
  width: 107px;
}
.grid .greyscalelegacy-269-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 980px;
  width: 107px;
}
.grid .greyscalelegacy-270-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 1085px;
  width: 106px;
}
.grid .greyscalelegacy-270-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 972px;
  width: 106px;
}
.grid .greyscalelegacy-271-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 1085px;
  width: 107px;
}
.grid .greyscalelegacy-271-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 972px;
  width: 107px;
}
.grid .greyscalelegacy-272-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 1085px;
  width: 106px;
}
.grid .greyscalelegacy-272-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 972px;
  width: 106px;
}
.grid .greyscalelegacy-273-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 1085px;
  width: 107px;
}
.grid .greyscalelegacy-273-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 972px;
  width: 107px;
}
.grid .greyscalelegacy-274-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 1085px;
  width: 107px;
}
.grid .greyscalelegacy-274-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 972px;
  width: 106px;
}
.grid .greyscalelegacy-275-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 1085px;
  width: 106px;
}
.grid .greyscalelegacy-275-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 972px;
  width: 106px;
}
.grid .greyscalelegacy-276-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 1085px;
  width: 106px;
}
.grid .greyscalelegacy-276-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 972px;
  width: 107px;
}
.grid .greyscalelegacy-277-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 1200px;
  width: 107px;
}
.grid .greyscalelegacy-277-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 1086px;
  width: 107px;
}
.grid .greyscalelegacy-278-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 1200px;
  width: 106px;
}
.grid .greyscalelegacy-278-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 1086px;
  width: 106px;
}
.grid .greyscalelegacy-279-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 1200px;
  width: 107px;
}
.grid .greyscalelegacy-279-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 1086px;
  width: 107px;
}
.grid .greyscalelegacy-280-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 1192px;
  width: 106px;
}
.grid .greyscalelegacy-280-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 1078px;
  width: 106px;
}
.grid .greyscalelegacy-281-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 1192px;
  width: 107px;
}
.grid .greyscalelegacy-281-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 1078px;
  width: 107px;
}
.grid .greyscalelegacy-282-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 1192px;
  width: 106px;
}
.grid .greyscalelegacy-282-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 1078px;
  width: 106px;
}
.grid .greyscalelegacy-283-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 1192px;
  width: 107px;
}
.grid .greyscalelegacy-283-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 1078px;
  width: 107px;
}
.grid .greyscalelegacy-284-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 1192px;
  width: 107px;
}
.grid .greyscalelegacy-284-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 1078px;
  width: 106px;
}
.grid .greyscalelegacy-285-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 1192px;
  width: 106px;
}
.grid .greyscalelegacy-285-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 1078px;
  width: 106px;
}
.grid .greyscalelegacy-286-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 1192px;
  width: 106px;
}
.grid .greyscalelegacy-286-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 1078px;
  width: 107px;
}
.grid .greyscalelegacy-287-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 1307px;
  width: 107px;
}
.grid .greyscalelegacy-287-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 1192px;
  width: 107px;
}
.grid .greyscalelegacy-288-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 1307px;
  width: 106px;
}
.grid .greyscalelegacy-288-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 1192px;
  width: 106px;
}
.grid .greyscalelegacy-289-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 348px;
  width: 107px;
}
.grid .greyscalelegacy-289-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 235px;
  width: 107px;
}
.grid .greyscalelegacy-290-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 340px;
  width: 106px;
}
.grid .greyscalelegacy-290-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 227px;
  width: 106px;
}
.grid .greyscalelegacy-291-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 340px;
  width: 107px;
}
.grid .greyscalelegacy-291-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 227px;
  width: 107px;
}
.grid .greyscalelegacy-292-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 340px;
  width: 106px;
}
.grid .greyscalelegacy-292-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 227px;
  width: 106px;
}
.grid .greyscalelegacy-293-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 340px;
  width: 107px;
}
.grid .greyscalelegacy-293-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 227px;
  width: 107px;
}
.grid .greyscalelegacy-294-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 340px;
  width: 107px;
}
.grid .greyscalelegacy-294-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 227px;
  width: 106px;
}
.grid .greyscalelegacy-295-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 340px;
  width: 106px;
}
.grid .greyscalelegacy-295-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 227px;
  width: 106px;
}
.grid .greyscalelegacy-296-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 340px;
  width: 106px;
}
.grid .greyscalelegacy-296-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 227px;
  width: 107px;
}
.grid .greyscalelegacy-297-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 454px;
  width: 107px;
}
.grid .greyscalelegacy-297-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 341px;
  width: 107px;
}
.grid .greyscalelegacy-298-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 454px;
  width: 106px;
}
.grid .greyscalelegacy-298-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 341px;
  width: 106px;
}
.grid .greyscalelegacy-299-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 454px;
  width: 107px;
}
.grid .greyscalelegacy-299-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 341px;
  width: 107px;
}
.grid .greyscalelegacy-3-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 2150px;
  width: 107px;
}
.grid .greyscalelegacy-3-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 2037px;
  width: 106px;
}
.grid .greyscalelegacy-300-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 446px;
  width: 106px;
}
.grid .greyscalelegacy-300-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 333px;
  width: 106px;
}
.grid .greyscalelegacy-301-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 446px;
  width: 107px;
}
.grid .greyscalelegacy-301-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 333px;
  width: 107px;
}
.grid .greyscalelegacy-302-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 446px;
  width: 106px;
}
.grid .greyscalelegacy-302-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 333px;
  width: 106px;
}
.grid .greyscalelegacy-303-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 446px;
  width: 107px;
}
.grid .greyscalelegacy-303-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 333px;
  width: 107px;
}
.grid .greyscalelegacy-304-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 446px;
  width: 107px;
}
.grid .greyscalelegacy-304-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 333px;
  width: 106px;
}
.grid .greyscalelegacy-305-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 446px;
  width: 106px;
}
.grid .greyscalelegacy-305-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 333px;
  width: 106px;
}
.grid .greyscalelegacy-306-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 446px;
  width: 106px;
}
.grid .greyscalelegacy-306-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 333px;
  width: 107px;
}
.grid .greyscalelegacy-307-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 561px;
  width: 107px;
}
.grid .greyscalelegacy-307-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 448px;
  width: 107px;
}
.grid .greyscalelegacy-308-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 561px;
  width: 106px;
}
.grid .greyscalelegacy-308-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 448px;
  width: 106px;
}
.grid .greyscalelegacy-309-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 561px;
  width: 107px;
}
.grid .greyscalelegacy-309-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 448px;
  width: 107px;
}
.grid .greyscalelegacy-310-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 553px;
  width: 106px;
}
.grid .greyscalelegacy-310-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 440px;
  width: 106px;
}
.grid .greyscalelegacy-311-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 553px;
  width: 107px;
}
.grid .greyscalelegacy-311-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 440px;
  width: 107px;
}
.grid .greyscalelegacy-312-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 553px;
  width: 106px;
}
.grid .greyscalelegacy-312-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 440px;
  width: 106px;
}
.grid .greyscalelegacy-313-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 553px;
  width: 107px;
}
.grid .greyscalelegacy-313-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 440px;
  width: 107px;
}
.grid .greyscalelegacy-314-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 553px;
  width: 107px;
}
.grid .greyscalelegacy-314-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 440px;
  width: 106px;
}
.grid .greyscalelegacy-315-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 553px;
  width: 106px;
}
.grid .greyscalelegacy-315-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 440px;
  width: 106px;
}
.grid .greyscalelegacy-316-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 553px;
  width: 106px;
}
.grid .greyscalelegacy-316-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 440px;
  width: 107px;
}
.grid .greyscalelegacy-317-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 667px;
  width: 107px;
}
.grid .greyscalelegacy-317-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 554px;
  width: 107px;
}
.grid .greyscalelegacy-318-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 667px;
  width: 106px;
}
.grid .greyscalelegacy-318-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 554px;
  width: 106px;
}
.grid .greyscalelegacy-319-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 667px;
  width: 107px;
}
.grid .greyscalelegacy-319-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 554px;
  width: 107px;
}
.grid .greyscalelegacy-320-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 659px;
  width: 106px;
}
.grid .greyscalelegacy-320-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 546px;
  width: 106px;
}
.grid .greyscalelegacy-321-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 659px;
  width: 107px;
}
.grid .greyscalelegacy-321-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 546px;
  width: 107px;
}
.grid .greyscalelegacy-322-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 659px;
  width: 106px;
}
.grid .greyscalelegacy-322-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 546px;
  width: 106px;
}
.grid .greyscalelegacy-323-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 659px;
  width: 107px;
}
.grid .greyscalelegacy-323-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 546px;
  width: 107px;
}
.grid .greyscalelegacy-324-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 659px;
  width: 107px;
}
.grid .greyscalelegacy-324-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 546px;
  width: 106px;
}
.grid .greyscalelegacy-325-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 659px;
  width: 106px;
}
.grid .greyscalelegacy-325-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 546px;
  width: 106px;
}
.grid .greyscalelegacy-326-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 659px;
  width: 106px;
}
.grid .greyscalelegacy-326-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 546px;
  width: 107px;
}
.grid .greyscalelegacy-327-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 774px;
  width: 107px;
}
.grid .greyscalelegacy-327-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 660px;
  width: 107px;
}
.grid .greyscalelegacy-328-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 774px;
  width: 106px;
}
.grid .greyscalelegacy-328-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 660px;
  width: 106px;
}
.grid .greyscalelegacy-329-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 774px;
  width: 107px;
}
.grid .greyscalelegacy-329-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 660px;
  width: 107px;
}
.grid .greyscalelegacy-33-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 2150px;
  width: 106px;
}
.grid .greyscalelegacy-33-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 2037px;
  width: 106px;
}
.grid .greyscalelegacy-330-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 766px;
  width: 106px;
}
.grid .greyscalelegacy-330-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 652px;
  width: 106px;
}
.grid .greyscalelegacy-331-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 766px;
  width: 107px;
}
.grid .greyscalelegacy-331-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 652px;
  width: 107px;
}
.grid .greyscalelegacy-332-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 766px;
  width: 106px;
}
.grid .greyscalelegacy-332-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 652px;
  width: 106px;
}
.grid .greyscalelegacy-333-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 766px;
  width: 107px;
}
.grid .greyscalelegacy-333-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 652px;
  width: 107px;
}
.grid .greyscalelegacy-334-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 766px;
  width: 107px;
}
.grid .greyscalelegacy-334-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 652px;
  width: 106px;
}
.grid .greyscalelegacy-335-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 766px;
  width: 106px;
}
.grid .greyscalelegacy-335-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 652px;
  width: 106px;
}
.grid .greyscalelegacy-336-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 766px;
  width: 106px;
}
.grid .greyscalelegacy-336-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 652px;
  width: 107px;
}
.grid .greyscalelegacy-337-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 880px;
  width: 107px;
}
.grid .greyscalelegacy-337-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 767px;
  width: 107px;
}
.grid .greyscalelegacy-338-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 880px;
  width: 106px;
}
.grid .greyscalelegacy-338-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 767px;
  width: 106px;
}
.grid .greyscalelegacy-339-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 880px;
  width: 107px;
}
.grid .greyscalelegacy-339-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 767px;
  width: 107px;
}
.grid .greyscalelegacy-34-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 2037px;
  width: 107px;
}
.grid .greyscalelegacy-340-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 872px;
  width: 106px;
}
.grid .greyscalelegacy-340-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 759px;
  width: 106px;
}
.grid .greyscalelegacy-35-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 329px;
  object-fit: cover;
  position: absolute;
  top: 1877px;
  width: 107px;
}
.grid .greyscalelegacy-35-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 2151px;
  width: 107px;
}
.grid .greyscalelegacy-36-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 2264px;
  width: 106px;
}
.grid .greyscalelegacy-36-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 2151px;
  width: 106px;
}
.grid .greyscalelegacy-37-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 2151px;
  width: 107px;
}
.grid .greyscalelegacy-38-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 2143px;
  width: 106px;
}
.grid .greyscalelegacy-39-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 2143px;
  width: 107px;
}
.grid .greyscalelegacy-394-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 134px;
  width: 107px;
}
.grid .greyscalelegacy-395-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 134px;
  width: 106px;
}
.grid .greyscalelegacy-396-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 134px;
  width: 107px;
}
.grid .greyscalelegacy-397-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 126px;
  width: 106px;
}
.grid .greyscalelegacy-398-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 126px;
  width: 107px;
}
.grid .greyscalelegacy-399-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 126px;
  width: 106px;
}
.grid .greyscalelegacy-40-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 2143px;
  width: 106px;
}
.grid .greyscalelegacy-400-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 126px;
  width: 107px;
}
.grid .greyscalelegacy-401-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 126px;
  width: 107px;
}
.grid .greyscalelegacy-402-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 126px;
  width: 106px;
}
.grid .greyscalelegacy-403-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 126px;
  width: 106px;
}
.grid .greyscalelegacy-404-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 241px;
  width: 107px;
}
.grid .greyscalelegacy-404-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 128px;
  width: 107px;
}
.grid .greyscalelegacy-405-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 241px;
  width: 106px;
}
.grid .greyscalelegacy-405-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 128px;
  width: 106px;
}
.grid .greyscalelegacy-406-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 241px;
  width: 107px;
}
.grid .greyscalelegacy-406-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 128px;
  width: 107px;
}
.grid .greyscalelegacy-407-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 233px;
  width: 106px;
}
.grid .greyscalelegacy-407-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 120px;
  width: 106px;
}
.grid .greyscalelegacy-408-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 233px;
  width: 107px;
}
.grid .greyscalelegacy-408-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 120px;
  width: 107px;
}
.grid .greyscalelegacy-409-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 233px;
  width: 106px;
}
.grid .greyscalelegacy-409-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 120px;
  width: 106px;
}
.grid .greyscalelegacy-410-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 233px;
  width: 107px;
}
.grid .greyscalelegacy-410-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 120px;
  width: 107px;
}
.grid .greyscalelegacy-411-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 233px;
  width: 107px;
}
.grid .greyscalelegacy-411-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 120px;
  width: 106px;
}
.grid .greyscalelegacy-412-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 233px;
  width: 106px;
}
.grid .greyscalelegacy-412-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 120px;
  width: 106px;
}
.grid .greyscalelegacy-413-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 651px;
  object-fit: cover;
  position: absolute;
  top: 233px;
  width: 106px;
}
.grid .greyscalelegacy-413-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 652px;
  object-fit: cover;
  position: absolute;
  top: 120px;
  width: 107px;
}
.grid .greyscalelegacy-414-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 348px;
  width: 107px;
}
.grid .greyscalelegacy-414-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 235px;
  width: 107px;
}
.grid .greyscalelegacy-415-C61RwL {
  background-color: transparent;
  height: 106px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 348px;
  width: 106px;
}
.grid .greyscalelegacy-415-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 235px;
  width: 106px;
}
.grid .greyscalelegacy-437-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 439px;
  object-fit: cover;
  position: absolute;
  top: 2803px;
  width: 107px;
}
.grid .greyscalelegacy-437-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 441px;
  object-fit: cover;
  position: absolute;
  top: 2689px;
  width: 107px;
}
.grid .greyscalelegacy-441-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 2803px;
  width: 107px;
}
.grid .greyscalelegacy-441-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 548px;
  object-fit: cover;
  position: absolute;
  top: 2689px;
  width: 107px;
}
.grid .greyscalelegacy-442-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 653px;
  object-fit: cover;
  position: absolute;
  top: 2803px;
  width: 107px;
}
.grid .greyscalelegacy-442-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 655px;
  object-fit: cover;
  position: absolute;
  top: 2689px;
  width: 107px;
}
.grid .greyscalelegacy-446-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: 2910px;
  width: 107px;
}
.grid .greyscalelegacy-446-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 13px;
  object-fit: cover;
  position: absolute;
  top: 2796px;
  width: 107px;
}
.grid .greyscalelegacy-447-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 2910px;
  width: 107px;
}
.grid .greyscalelegacy-447-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 2796px;
  width: 107px;
}
.grid .greyscalelegacy-448-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 2910px;
  width: 107px;
}
.grid .greyscalelegacy-448-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 2796px;
  width: 107px;
}
.grid .greyscalelegacy-449-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 332px;
  object-fit: cover;
  position: absolute;
  top: 2910px;
  width: 107px;
}
.grid .greyscalelegacy-449-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 334px;
  object-fit: cover;
  position: absolute;
  top: 2796px;
  width: 107px;
}
.grid .greyscalelegacy-450-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 439px;
  object-fit: cover;
  position: absolute;
  top: 2910px;
  width: 107px;
}
.grid .greyscalelegacy-450-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 441px;
  object-fit: cover;
  position: absolute;
  top: 2796px;
  width: 107px;
}
.grid .greyscalelegacy-451-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 2910px;
  width: 107px;
}
.grid .greyscalelegacy-451-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 548px;
  object-fit: cover;
  position: absolute;
  top: 2796px;
  width: 107px;
}
.grid .greyscalelegacy-452-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 653px;
  object-fit: cover;
  position: absolute;
  top: 2910px;
  width: 107px;
}
.grid .greyscalelegacy-452-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 655px;
  object-fit: cover;
  position: absolute;
  top: 2796px;
  width: 107px;
}
.grid .greyscalelegacy-456-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: 3017px;
  width: 107px;
}
.grid .greyscalelegacy-456-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 13px;
  object-fit: cover;
  position: absolute;
  top: 2903px;
  width: 107px;
}
.grid .greyscalelegacy-457-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 3017px;
  width: 107px;
}
.grid .greyscalelegacy-457-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 2903px;
  width: 107px;
}
.grid .greyscalelegacy-458-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 3017px;
  width: 107px;
}
.grid .greyscalelegacy-458-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 2903px;
  width: 107px;
}
.grid .greyscalelegacy-459-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 332px;
  object-fit: cover;
  position: absolute;
  top: 3017px;
  width: 107px;
}
.grid .greyscalelegacy-459-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 334px;
  object-fit: cover;
  position: absolute;
  top: 2903px;
  width: 107px;
}
.grid .greyscalelegacy-460-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 439px;
  object-fit: cover;
  position: absolute;
  top: 3017px;
  width: 107px;
}
.grid .greyscalelegacy-460-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 441px;
  object-fit: cover;
  position: absolute;
  top: 2903px;
  width: 107px;
}
.grid .greyscalelegacy-461-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 3017px;
  width: 107px;
}
.grid .greyscalelegacy-461-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 548px;
  object-fit: cover;
  position: absolute;
  top: 2903px;
  width: 107px;
}
.grid .greyscalelegacy-462-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 653px;
  object-fit: cover;
  position: absolute;
  top: 3017px;
  width: 107px;
}
.grid .greyscalelegacy-462-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 655px;
  object-fit: cover;
  position: absolute;
  top: 2903px;
  width: 107px;
}
.grid .greyscalelegacy-476-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 13px;
  object-fit: cover;
  position: absolute;
  top: 3010px;
  width: 107px;
}
.grid .greyscalelegacy-479-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 3010px;
  width: 107px;
}
.grid .greyscalelegacy-484-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 3010px;
  width: 107px;
}
.grid .greyscalelegacy-491-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 334px;
  object-fit: cover;
  position: absolute;
  top: 3010px;
  width: 107px;
}
.grid .greyscalelegacy-492-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 441px;
  object-fit: cover;
  position: absolute;
  top: 3010px;
  width: 107px;
}
.grid .greyscalelegacy-493-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 548px;
  object-fit: cover;
  position: absolute;
  top: 3010px;
  width: 107px;
}
.grid .greyscalelegacy-494-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 655px;
  object-fit: cover;
  position: absolute;
  top: 3010px;
  width: 107px;
}
.grid .greyscalelegacy-519-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: 2268px;
  width: 107px;
}
.grid .greyscalelegacy-52-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 2143px;
  width: 107px;
}
.grid .greyscalelegacy-520-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 2268px;
  width: 107px;
}
.grid .greyscalelegacy-521-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 2268px;
  width: 107px;
}
.grid .greyscalelegacy-522-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 332px;
  object-fit: cover;
  position: absolute;
  top: 2268px;
  width: 107px;
}
.grid .greyscalelegacy-523-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 439px;
  object-fit: cover;
  position: absolute;
  top: 2268px;
  width: 107px;
}
.grid .greyscalelegacy-524-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 2268px;
  width: 107px;
}
.grid .greyscalelegacy-525-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 653px;
  object-fit: cover;
  position: absolute;
  top: 2268px;
  width: 107px;
}
.grid .greyscalelegacy-529-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: 2375px;
  width: 107px;
}
.grid .greyscalelegacy-530-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 2375px;
  width: 107px;
}
.grid .greyscalelegacy-531-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 2375px;
  width: 107px;
}
.grid .greyscalelegacy-532-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 332px;
  object-fit: cover;
  position: absolute;
  top: 2375px;
  width: 107px;
}
.grid .greyscalelegacy-533-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 439px;
  object-fit: cover;
  position: absolute;
  top: 2375px;
  width: 107px;
}
.grid .greyscalelegacy-534-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 2375px;
  width: 107px;
}
.grid .greyscalelegacy-535-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 653px;
  object-fit: cover;
  position: absolute;
  top: 2375px;
  width: 107px;
}
.grid .greyscalelegacy-539-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: 2482px;
  width: 107px;
}
.grid .greyscalelegacy-539-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 13px;
  object-fit: cover;
  position: absolute;
  top: 2368px;
  width: 107px;
}
.grid .greyscalelegacy-540-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 2482px;
  width: 107px;
}
.grid .greyscalelegacy-540-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 2368px;
  width: 107px;
}
.grid .greyscalelegacy-541-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 2482px;
  width: 107px;
}
.grid .greyscalelegacy-541-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 2368px;
  width: 107px;
}
.grid .greyscalelegacy-542-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 332px;
  object-fit: cover;
  position: absolute;
  top: 2482px;
  width: 107px;
}
.grid .greyscalelegacy-542-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 334px;
  object-fit: cover;
  position: absolute;
  top: 2368px;
  width: 107px;
}
.grid .greyscalelegacy-543-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 439px;
  object-fit: cover;
  position: absolute;
  top: 2482px;
  width: 107px;
}
.grid .greyscalelegacy-543-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 441px;
  object-fit: cover;
  position: absolute;
  top: 2368px;
  width: 107px;
}
.grid .greyscalelegacy-544-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 2482px;
  width: 107px;
}
.grid .greyscalelegacy-544-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 548px;
  object-fit: cover;
  position: absolute;
  top: 2368px;
  width: 107px;
}
.grid .greyscalelegacy-545-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 653px;
  object-fit: cover;
  position: absolute;
  top: 2482px;
  width: 107px;
}
.grid .greyscalelegacy-545-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 655px;
  object-fit: cover;
  position: absolute;
  top: 2368px;
  width: 107px;
}
.grid .greyscalelegacy-549-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: 2589px;
  width: 107px;
}
.grid .greyscalelegacy-549-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 13px;
  object-fit: cover;
  position: absolute;
  top: 2475px;
  width: 107px;
}
.grid .greyscalelegacy-550-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 2589px;
  width: 107px;
}
.grid .greyscalelegacy-550-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 2475px;
  width: 107px;
}
.grid .greyscalelegacy-551-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 2589px;
  width: 107px;
}
.grid .greyscalelegacy-551-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 2475px;
  width: 107px;
}
.grid .greyscalelegacy-552-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 332px;
  object-fit: cover;
  position: absolute;
  top: 2589px;
  width: 107px;
}
.grid .greyscalelegacy-552-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 334px;
  object-fit: cover;
  position: absolute;
  top: 2475px;
  width: 107px;
}
.grid .greyscalelegacy-553-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 439px;
  object-fit: cover;
  position: absolute;
  top: 2589px;
  width: 107px;
}
.grid .greyscalelegacy-553-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 441px;
  object-fit: cover;
  position: absolute;
  top: 2475px;
  width: 107px;
}
.grid .greyscalelegacy-554-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 2589px;
  width: 107px;
}
.grid .greyscalelegacy-554-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 548px;
  object-fit: cover;
  position: absolute;
  top: 2475px;
  width: 107px;
}
.grid .greyscalelegacy-555-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 653px;
  object-fit: cover;
  position: absolute;
  top: 2589px;
  width: 107px;
}
.grid .greyscalelegacy-555-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 655px;
  object-fit: cover;
  position: absolute;
  top: 2475px;
  width: 107px;
}
.grid .greyscalelegacy-559-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: 2696px;
  width: 107px;
}
.grid .greyscalelegacy-559-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 13px;
  object-fit: cover;
  position: absolute;
  top: 2582px;
  width: 107px;
}
.grid .greyscalelegacy-560-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 2696px;
  width: 107px;
}
.grid .greyscalelegacy-560-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 2582px;
  width: 107px;
}
.grid .greyscalelegacy-561-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 2696px;
  width: 107px;
}
.grid .greyscalelegacy-561-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 2582px;
  width: 107px;
}
.grid .greyscalelegacy-562-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 332px;
  object-fit: cover;
  position: absolute;
  top: 2696px;
  width: 107px;
}
.grid .greyscalelegacy-562-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 334px;
  object-fit: cover;
  position: absolute;
  top: 2582px;
  width: 107px;
}
.grid .greyscalelegacy-563-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 439px;
  object-fit: cover;
  position: absolute;
  top: 2696px;
  width: 107px;
}
.grid .greyscalelegacy-563-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 441px;
  object-fit: cover;
  position: absolute;
  top: 2582px;
  width: 107px;
}
.grid .greyscalelegacy-564-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 2696px;
  width: 107px;
}
.grid .greyscalelegacy-564-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 548px;
  object-fit: cover;
  position: absolute;
  top: 2582px;
  width: 107px;
}
.grid .greyscalelegacy-565-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 653px;
  object-fit: cover;
  position: absolute;
  top: 2696px;
  width: 107px;
}
.grid .greyscalelegacy-565-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 655px;
  object-fit: cover;
  position: absolute;
  top: 2582px;
  width: 107px;
}
.grid .greyscalelegacy-569-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: 2803px;
  width: 107px;
}
.grid .greyscalelegacy-569-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 13px;
  object-fit: cover;
  position: absolute;
  top: 2689px;
  width: 107px;
}
.grid .greyscalelegacy-570-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 2803px;
  width: 107px;
}
.grid .greyscalelegacy-570-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 2689px;
  width: 107px;
}
.grid .greyscalelegacy-571-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 2803px;
  width: 107px;
}
.grid .greyscalelegacy-571-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 2689px;
  width: 107px;
}
.grid .greyscalelegacy-572-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 332px;
  object-fit: cover;
  position: absolute;
  top: 2803px;
  width: 107px;
}
.grid .greyscalelegacy-572-VMr6Om {
  background-color: transparent;
  height: 107px;
  left: 334px;
  object-fit: cover;
  position: absolute;
  top: 2689px;
  width: 107px;
}
.grid .greyscalelegacy-83-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 990px;
  object-fit: cover;
  position: absolute;
  top: 1945px;
  width: 107px;
}
.grid .greyscalelegacy-83-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 988px;
  object-fit: cover;
  position: absolute;
  top: 1832px;
  width: 107px;
}
.grid .greyscalelegacy-86-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 884px;
  object-fit: cover;
  position: absolute;
  top: 1945px;
  width: 106px;
}
.grid .greyscalelegacy-86-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 1832px;
  width: 106px;
}
.grid .greyscalelegacy-95-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 1945px;
  width: 107px;
}
.grid .greyscalelegacy-95-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 775px;
  object-fit: cover;
  position: absolute;
  top: 1832px;
  width: 107px;
}
.grid .greyscalelegacy-96-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 1937px;
  width: 106px;
}
.grid .greyscalelegacy-96-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 1824px;
  width: 106px;
}
.grid .greyscalelegacy-97-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 118px;
  object-fit: cover;
  position: absolute;
  top: 1937px;
  width: 107px;
}
.grid .greyscalelegacy-97-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 1824px;
  width: 107px;
}
.grid .greyscalelegacy-98-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 1937px;
  width: 106px;
}
.grid .greyscalelegacy-98-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 1824px;
  width: 106px;
}
.grid .greyscalelegacy-99-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 331px;
  object-fit: cover;
  position: absolute;
  top: 1937px;
  width: 107px;
}
.grid .greyscalelegacy-99-VMr6Om {
  background-color: transparent;
  height: 106px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 1824px;
  width: 107px;
}
.grid .grotto-labs-logo-3-C61RwL {
  background-color: transparent;
  height: 78px;
  left: 93px;
  object-fit: cover;
  position: absolute;
  top: 3219px;
  width: 67px;
}
.grid .xadt00000099j48-balegacy212-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 2143px;
  width: 106px;
}
.grid .xadt0000009bj48-balegacy972-C61RwL {
  background-color: transparent;
  height: 107px;
  left: 440px;
  object-fit: cover;
  position: absolute;
  top: 2143px;
  width: 106px;
}
