/* screen - wallet-true */

.wallet-true {
  align-items: flex-end;
  background-color: var(--white);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: auto;
  min-height:1200px;
  justify-content: flex-start;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 33px 64.5px;
  position: relative;
  width: 789px;
}

.loading{
  background-color: transparent;
  flex-shrink: 1;
  height: 40px;
  margin-left: 190px;
  object-fit: cover;
  position: relative;
  width: 286px;
}

.wallet-true .asset-18-prov-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 15px;
  margin-bottom: 1.0px;
  margin-left: 42px;
  object-fit: cover;
  position: relative;
  width: 68px;
}
.wallet-true .asset-20-gallery-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 15px;
  margin-bottom: 1.0px;
  margin-left: 31px;
  object-fit: cover;
  position: relative;
  width: 85px;
}
.wallet-true .asset-21-purchase-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 17px;
  margin-bottom: 1.0px;
  margin-left: 49px;
  object-fit: cover;
  position: relative;
  width: 83px;
}
.wallet-true .asset-23-wallet-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 15px;
  margin-bottom: 1.0px;
  margin-left: 29px;
  object-fit: cover;
  position: relative;
  width: 99px;
}
.wallet-true .asset-23-wallet-2-GyJUNH {
  background-color: transparent;
  flex-shrink: 1;
  height: 40px;
  margin-left: 83px;
  object-fit: cover;
  position: relative;
  width: 286px;
}
.wallet-true .asset-26-j48-messy-1-GqJPlL {
  background-color: transparent;
  height: 120px;
  left: 196px;
  object-fit: cover;
  position: absolute;
  top: 31px;
  width: 190px;
}
.wallet-true .asset-28-bounty-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 20px;
  margin-left: 30px;
  object-fit: cover;
  position: relative;
  width: 88px;
}
.wallet-true .asset-3-l1-2-GqJPlL {
  background-color: transparent;
  height: 55px;
  left: 36px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 488px;
}
.wallet-true .asset-31-main-1-dXBp89 {
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 1;
  height: 15px;
  margin-bottom: 1.0px;
  object-fit: cover;
  position: relative;
  width: 51px;
}
.wallet-true .asset-34-twitter-1-BISixz {
  background-color: transparent;
  flex-shrink: 1;
  height: 28px;
  margin-left: 14px;
  margin-top: 35.52px;
  object-fit: cover;
  position: relative;
  width: 33px;
}
.wallet-true .asset-35-discord-1-GqJPlL {
  background-color: transparent;
  height: 22px;
  left: 498px;
  object-fit: cover;
  position: absolute;
  top: 83px;
  width: 40px;
}
.wallet-true .asset-38-wallett-2-GyJUNH {
  background-color: transparent;
  flex-shrink: 1;
  height: 60px;
  margin-left: 83px;
  object-fit: cover;
  position: relative;
  width: 102px;
}
.wallet-true .asset-38-wallett-3-GyJUNH {
  background-color: transparent;
  flex-shrink: 1;
  height: 60px;
  object-fit: cover;
  position: relative;
  width: 102px;
}
.wallet-true .asset-41-grottolab1-1-GqJPlL {
  background-color: transparent;
  height: 48px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 60px;
  width: 58px;
}
.wallet-true .flex-col-C61RwL {
  align-items: flex-start;
  align-self: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 1.0px;
  margin-top: 65px;
  min-height: 2154px;
  position: relative;
  width: 656px;
}
.wallet-true .flex-row-0aLMTk {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 269px;
  justify-content: flex-start;
  margin-left: 25px;
  margin-top: 99px;
  min-width: 471px;
  position: relative;
  width: auto;
}
.wallet-true .flex-row-0xy0vn {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 60px;
  justify-content: flex-start;
  min-width: 656px;
  position: relative;
  width: auto;
}
.wallet-true .flex-row-4iD7zR {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 269px;
  justify-content: flex-start;
  margin-left: 25px;
  margin-top: 60px;
  min-width: 471px;
  position: relative;
  width: auto;
}
.wallet-true .flex-row-C61RwL {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 20px;
  justify-content: flex-start;
  min-width: 657px;
  position: relative;
  width: auto;
}
.wallet-true .flex-row-OtmKqf {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 269px;
  justify-content: flex-start;
  margin-left: 25px;
  margin-top: 41px;
  min-width: 452px;
  position: relative;
  width: auto;
}
.wallet-true .flex-row-exd5BQ {
  align-items: center;
  align-self: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 150px;
  justify-content: flex-start;
  margin-left: 31.0px;
  margin-top: 0px;
  min-width: 585px;
  position: relative;
  width: auto;
}
.wallet-true .flex-row-oV5UrE {
  align-items: flex-start;
  align-self: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 66px;
  justify-content: flex-start;
  margin-right: 3.0px;
  margin-top: 65px;
  min-width: 467px;
  position: relative;
  width: auto;
}
.wallet-true .flex-row-t6Autm {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 269px;
  justify-content: flex-start;
  margin-left: 25px;
  margin-top: 33px;
  min-width: 452px;
  position: relative;
  width: auto;
}
.wallet-true .flex-row-uPORJx {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 269px;
  justify-content: flex-start;
  margin-left: 25px;
  margin-top: 41px;
  min-width: 471px;
  position: relative;
  width: auto;
}
.wallet-true .frame-2-0xy0vn {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  margin-right: 92.44px;
  margin-top: 192px;
  overflow: hidden;
  position: relative;
  width: 6px;
}
.wallet-true .grotto-labs-logo-4-GqJPlL {
  background-color: transparent;
  height: 78px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  top: 55px;
  width: 67px;
}
.wallet-true .image-1-ossvxU {
  background-color: transparent;
  flex-shrink: 1;
  height: 269px;
  object-fit: cover;
  position: relative;
  width: 269px;
}
.wallet-true .image-2-gD87cZ {
  background-color: transparent;
  flex-shrink: 1;
  height: 269px;
  object-fit: cover;
  position: relative;
  width: 269px;
}
.wallet-true .image-3-RgyNpo {
  background-color: transparent;
  flex-shrink: 1;
  height: 269px;
  object-fit: cover;
  position: relative;
  width: 269px;
}
.wallet-true .image-4-xWQcfe {
  background-color: transparent;
  flex-shrink: 1;
  height: 269px;
  object-fit: cover;
  position: relative;
  width: 269px;
}
.wallet-true .image-5-QkTteQ {
  background-color: transparent;
  flex-shrink: 1;
  height: 62px;
  object-fit: cover;
  position: relative;
  width: 62px;
}
.wallet-true .image-6-O2UJZ2 {
  background-color: transparent;
  flex-shrink: 1;
  height: 269px;
  object-fit: cover;
  position: relative;
  width: 269px;
}
.wallet-true .j48-baform-0532-e-ganj48-baform-RgyNpo {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0.00px;
  line-height: normal;
  margin-left: 34px;
  margin-top: 51px;
  min-height: 44px;
  min-width: 168px;
  position: relative;
  text-align: left;
  width: auto;
}
.wallet-true .j48-baform-1986-type-legacyform-gD87cZ {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0.00px;
  line-height: normal;
  margin-left: 34px;
  margin-top: 51px;
  min-height: 44px;
  min-width: 149px;
  position: relative;
  text-align: left;
  width: auto;
}
.wallet-true .j48-baform-1988-type-legacyform-xWQcfe {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0.00px;
  line-height: normal;
  margin-left: 34px;
  margin-top: 51px;
  min-height: 44px;
  min-width: 149px;
  position: relative;
  text-align: left;
  width: auto;
}
.wallet-true .j48-baform-2397-e-ganj48-baform-ossvxU {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0.00px;
  line-height: normal;
  margin-left: 34px;
  margin-top: 51px;
  min-height: 44px;
  min-width: 168px;
  position: relative;
  text-align: left;
  width: auto;
}
.wallet-true .j48-baform-3652-e-ganj48-baform-O2UJZ2 {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0.00px;
  line-height: normal;
  margin-left: 34px;
  margin-top: 51px;
  min-height: 44px;
  min-width: 168px;
  position: relative;
  text-align: left;
  width: auto;
}
.wallet-true .overlap-group-BISixz {
  background-color: transparent;
  flex-shrink: 1;
  height: 150px;
  position: relative;
  width: 538px;
}
.wallet-true .your-wallet-0xc-d53-c511-a30631-QkTteQ {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0.00px;
  line-height: normal;
  margin-left: 17px;
  min-height: 44px;
  min-width: 388px;
  position: relative;
  text-align: left;
  width: auto;
}
