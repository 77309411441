*,
*:before,
*:after {
  box-sizing: border-box;
}

img {
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

input[type="range"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image:  url("/img/j8-slider-track.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: auto;
    margin-top: 15px;
    margin-bottom: 15px;
    outline: none;
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    display:block;
    width:100%;
  }

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("/img/pickaxe-slider-handle.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    border: 0;
    border-radius: 0px;
    cursor: pointer;
    height: 100px;
    width: 100px;
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    --thumb-size: 20;
}

input[type=range]::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("/img/pickaxe-slider-handle.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  border: 0;
  border-radius: 0px;
  cursor: pointer;
  height: 100px;
  width: 100px;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  --thumb-size: 20;
}






.slider-wrapper{
    width: 650px;
    position: relative;
    left: -600px;
    border: none;
    z-index: 100;
    background: none;
    top: 60px;
    height:100px;
}
